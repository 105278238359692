import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

import reducer from 'reducers'
import api from 'middleware/api'

const middleware = applyMiddleware(thunk, promise, api)

export default createStore(reducer, composeWithDevTools(middleware))
