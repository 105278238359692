import {
  CLIENT_TYPE_REQUEST,
  CLIENT_TYPE_SUCCESS,
  CLIENT_TYPE_FAILURE,
} from 'actions/userActions'
import { UserState, clientTypeActionTypes } from './types'

const initialState: UserState = {
  isFetching: false,
  data: undefined,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  action: clientTypeActionTypes,
): UserState {
  switch (action.type) {
    case CLIENT_TYPE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        data: action.data,
      })

    case CLIENT_TYPE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case CLIENT_TYPE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    default:
      return state
  }
}
