import {
  ORDERS_PENDING_REQUEST,
  ORDERS_PENDING_SUCCESS,
  ORDERS_PENDING_FAILURE,
  SELECT_ORDER,
  UPDATE_ORDER_PENDING_FILTERS,
  UPDATE_PENDING_STATUS_REQUEST,
  UPDATE_PENDING_STATUS_SUCCESS,
  UPDATE_PENDING_STATUS_FAILURE,
} from 'actions/dashboardActions'
import { WS_MESSAGE } from 'actions/wsActions'
import orderActions from 'actions/orderActions'

export default function reducer(
  state = {
    isFetching: false,
    isDeleting: false,
    isUpdating: true,
    data: {},
    stream: null,
    errorsList: {},
    errors: {},
    reloadDetail: false,
    selected: false,
    cancel: false,
    filters: {
      search: '',
    },
    needRefresh: false,
  },
  action,
) {
  switch (action.type) {
    case ORDERS_PENDING_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        reloadDetail: false,
        needRefresh: false,
      })

    case ORDERS_PENDING_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case ORDERS_PENDING_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorsList: action.errors,
      })

    case WS_MESSAGE:
      let newObject = {}
      if (
        action.entity === 'ORDER_LIST_ACTIVE' &&
        action.data.stream === 'order'
      ) {
        newObject = {
          reloadDetail: true,
          stream: action.data,
        }
      } else if (
        action.entity === 'ORDER_LIST_ACTIVE' &&
        action.data.stream === 'destination'
      ) {
        newObject = {
          reloadDetail: true,
          stream: action.data,
        }
      }
      return Object.assign({}, state, newObject)

    case SELECT_ORDER:
      return Object.assign({}, state, {
        selected: action.data,
        errors: false,
        cancel: false,
      })

    case orderActions.ORDER_CANCEL_REQUEST:
      return Object.assign({}, state, {
        isDeleting: true,
        cancel: false,
        errors: false,
      })

    case orderActions.ORDER_CANCEL_SUCCESS:
      let results = state.data.results.filter(
        order => order['id'] !== action.extra,
      )

      let data = Object.assign({}, state.data, {
        results: results,
      })

      return Object.assign({}, state, {
        isDeleting: false,
        cancel: true,
        errors: false,
        data: data,
      })

    case orderActions.ORDER_CANCEL_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        cancel: false,
        errors: action.errors,
      })
    case UPDATE_ORDER_PENDING_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true,
      })

    case UPDATE_PENDING_STATUS_REQUEST:
      return Object.assign({}, state, {
        isUpdating: true,
      })
    case UPDATE_PENDING_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        needRefresh: true,
      })
    case UPDATE_PENDING_STATUS_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false,
      })

    default:
      return state
  }
}
