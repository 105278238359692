export interface Toast {
  type: string
  title: string
  message: string
  duration?: number
}

export class FancyToast implements Toast {
  type: string
  title: string
  message: string
  duration: number

  constructor(
    type: string,
    title: string,
    message: string,
    duration: number = 8000,
  ) {
    this.type = type
    this.title = title
    this.message = message
    this.duration = duration
  }
}
