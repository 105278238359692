import {
  LIST_DRAFTS_REQUEST,
  LIST_DRAFTS_SUCCESS,
  LIST_DRAFTS_FAILURE,
  DELETE_DRAFT_REQUEST,
  DELETE_DRAFT_SUCCESS,
  DELETE_DRAFT_FAILURE,
} from 'actions/dashboardActions'

export default function reducer(
  state = {
    isDeleting: false,
    isFetching: false,
    data: {},
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case LIST_DRAFTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })

    case LIST_DRAFTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case LIST_DRAFTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    case DELETE_DRAFT_REQUEST:
      return Object.assign({}, state, {
        isDeleting: true,
      })

    case DELETE_DRAFT_SUCCESS:
      window.location = '/borradores'
      return Object.assign({}, state, {
        isDeleting: false,
      })

    case DELETE_DRAFT_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
      })

    default:
      return state
  }
}
