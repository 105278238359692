import { CALL_API } from 'middleware/api'

export const COUPON_REQUEST = 'COUPON_REQUEST'
export const COUPON_SUCCESS = 'COUPON_SUCCESS'
export const COUPON_FAILURE = 'COUPON_FAILURE'
export const CREDIT_SUCCESS = 'CREDIT_SUCCESS'
export const LIST_CARDS_REQUEST = 'LIST_CARDS_REQUEST'
export const LIST_CARDS_SUCCESS = 'LIST_CARDS_SUCCESS'
export const LIST_CARDS_FAILURE = 'LIST_CARDS_FAILURE'
export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST'
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS'
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE'
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
export const GET_WEBHOOK_REQUEST = 'GET_WEBHOOK_REQUEST'
export const GET_WEBHOOK_SUCCESS = 'GET_WEBHOOK_SUCCESS'
export const GET_WEBHOOK_FAILURE = 'GET_WEBHOOK_FAILURE'
export const UPDATE_WEBHOOK_REQUEST = 'UPDATE_WEBHOOK_REQUEST'
export const UPDATE_WEBHOOK_SUCCESS = 'UPDATE_WEBHOOK_SUCCESS'
export const UPDATE_WEBHOOK_FAILURE = 'UPDATE_WEBHOOK_FAILURE'
export const INTEGRATE_BSALE_REQUEST = 'INTEGRATE_BSALE_REQUEST'
export const INTEGRATE_BSALE_SUCCESS = 'INTEGRATE_BSALE_SUCCESS'
export const INTEGRATE_BSALE_FAILURE = 'INTEGRATE_BSALE_FAILURE'

export function addCoupon(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `coupon_registrations/`,
      authenticated: true,
      data,
      types: [COUPON_REQUEST, COUPON_SUCCESS, COUPON_FAILURE],
    },
  }
}

export function addCreditCard(newCard) {
  return dispatch => {
    const action = {
      type: CREDIT_SUCCESS,
      newCard,
    }
    dispatch(action)
  }
}

export function listCreditCards() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `bankcards/`,
      authenticated: true,
      types: [LIST_CARDS_REQUEST, LIST_CARDS_SUCCESS, LIST_CARDS_FAILURE],
    },
  }
}

export function deleteCard(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `bankcards/${id}/`,
      authenticated: true,
      extra: id,
      types: [DELETE_CARD_REQUEST, DELETE_CARD_SUCCESS, DELETE_CARD_FAILURE],
    },
  }
}

export function changePassword(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `password/`,
      authenticated: true,
      data: data,
      types: [
        CHANGE_PASSWORD_REQUEST,
        CHANGE_PASSWORD_SUCCESS,
        CHANGE_PASSWORD_FAILURE,
      ],
    },
  }
}

export function getWebhook() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `profile/webhook/`,
      authenticated: true,
      types: [GET_WEBHOOK_REQUEST, GET_WEBHOOK_SUCCESS, GET_WEBHOOK_FAILURE],
    },
  }
}

export function updateWebhook(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `profile/`,
      authenticated: true,
      data: data,
      types: [
        UPDATE_WEBHOOK_REQUEST,
        UPDATE_WEBHOOK_SUCCESS,
        UPDATE_WEBHOOK_FAILURE,
      ],
    },
  }
}

export function integrateBsale(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `profile/`,
      authenticated: true,
      data: data,
      types: [
        INTEGRATE_BSALE_REQUEST,
        INTEGRATE_BSALE_SUCCESS,
        INTEGRATE_BSALE_FAILURE,
      ],
    },
  }
}
