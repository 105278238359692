import {
  COUPON_REQUEST,
  COUPON_SUCCESS,
  COUPON_FAILURE,
} from 'actions/accountActions'
import { CouponActionTypes, CouponsState } from './types'

const initialState: CouponsState = {
  isFetching: false,
  data: undefined,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  action: CouponActionTypes,
) {
  switch (action.type) {
    case COUPON_REQUEST:
      return { ...state, isFetching: true, data: action.data }

    case COUPON_SUCCESS:
      return { ...state, isFetching: false, data: action.data }

    case COUPON_FAILURE:
      return { ...state, isFetching: false, errors: action.data }

    default:
      return state
  }
}
