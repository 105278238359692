import { useState, useEffect } from 'react'
import { FancyToast, Toast } from 'utils/js/toastModel'

const useFancyToast = () => {
  const [toast, setToast] = useState<Toast | null>(null)

  useEffect(() => {
    if (toast) {
      const timer = setTimeout(() => {
        setToast(null)
      }, toast.duration || 8000)
      return () => clearTimeout(timer)
    }
    return undefined
  }, [toast])

  const showToast = (
    type: string,
    title: string,
    message: string,
    duration?: number,
  ) => {
    setToast(new FancyToast(type, title, message, duration))
  }

  return { toast, setToast, showToast }
}

export default useFancyToast
