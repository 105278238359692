export const FancyToastStyle = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
}

export const getToastStyle = type => {
  switch (type) {
    case FancyToastStyle.ERROR:
      return { color: 'red', icon: '❌' }
    case FancyToastStyle.WARNING:
      return { color: 'orange', icon: '⚠️' }
    case FancyToastStyle.SUCCESS:
      return { color: 'green', icon: '✅' }
    case FancyToastStyle.INFO:
      return { color: 'blue', icon: 'ℹ️' }
    default:
      return { color: 'gray', icon: 'ℹ️' }
  }
}
