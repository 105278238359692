import { CALL_API } from 'middleware/api'

export const GET_PACKAGE_TYPES_REQUEST = 'GET_PACKAGE_TYPES_REQUEST'
export const GET_PACKAGE_TYPES_SUCCESS = 'GET_PACKAGE_TYPES_SUCCESS'
export const GET_PACKAGE_TYPES_FAILURE = 'GET_PACKAGE_TYPES_FAILURE'

export function getPackageTypes() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'package_types/',
      authenticated: true,
      types: [
        GET_PACKAGE_TYPES_REQUEST,
        GET_PACKAGE_TYPES_SUCCESS,
        GET_PACKAGE_TYPES_FAILURE,
      ],
    },
  }
}
