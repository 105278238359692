import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  BrowserRouterProps,
  Switch,
  Route,
} from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'

import { AuthProvider, AuthContext } from './AuthContext'

const AsyncTrackingContainer = React.lazy(() =>
  import('./containers/TrackingContainer'),
)
const AsyncPublicContainer = React.lazy(() =>
  import('./containers/PublicContainer'),
)
const AsyncPrivateContainer = React.lazy(() =>
  import('./containers/PrivateContainer'),
)

const ProtectedRouter: React.FC<BrowserRouterProps> = props => {
  const context = React.useContext(AuthContext)
  const hash = window.location.search.split('=')[1]

  if (hash && window.location.pathname.match(/tracking/)) {
    return <AsyncTrackingContainer hashTracking={hash} {...props} />
  }

  if (hash && window.location.pathname.match(/login/)) {
    localStorage.token_web = hash
    return <Redirect to="/crear/pedido/" />
  }
  return (
    <Switch>
      <Route
        path="/"
        component={
          context.isAuthenticated ? AsyncPrivateContainer : AsyncPublicContainer
        }
      />
    </Switch>
  )
}

export function App() {
  return (
    <React.Suspense fallback={<div />}>
      <Provider store={store}>
        <AuthProvider>
          <Router>
            <Switch>
              <ProtectedRouter />
            </Switch>
          </Router>
        </AuthProvider>
      </Provider>
    </React.Suspense>
  )
}
