class Mixpanel {
  constructor() {
    this.useMixpanel = false
    if (
      window.mixpanel &&
      window.mixpanel.init &&
      (process.env.REACT_APP_URBANER_ENV === 'production' ||
        process.env.REACT_APP_URBANER_ENV === 'development' ||
        process.env.REACT_APP_URBANER_ENV === 'local')
    ) {
      window.mixpanel.init(process.env.REACT_APP_MIXPANEL, {
        debug: process.env.REACT_APP_URBANER_ENV === 'development',
      })
      this.useMixpanel = true
    }
  }

  loginSuccess(userId) {
    if (this.useMixpanel) {
      window.mixpanel.identify(userId)
      window.mixpanel.track('Login Success')
    }
  }

  signupSuccess(userId) {
    if (this.useMixpanel) {
      window.mixpanel.alias(userId)
      window.mixpanel.track('Signup Success')
    }
  }

  logout() {
    if (this.useMixpanel) {
      window.mixpanel.reset()
    }
  }

  landingPageViewed() {
    if (this.useMixpanel) {
      window.mixpanel.track('Home Page')
    }
  }

  registerPageViewed() {
    if (this.useMixpanel) {
      window.mixpanel.track('Signup Page')
    }
  }

  registerPersonPageViewed() {
    if (this.useMixpanel) {
      window.mixpanel.track('Signup Person Page')
    }
  }

  registerBusinessPageViewed() {
    if (this.useMixpanel) {
      window.mixpanel.track('Signup Business Page')
    }
  }

  registerCodePageViewed() {
    if (this.useMixpanel) {
      window.mixpanel.track('Signup Code Page')
    }
  }

  activePageViewed() {
    if (this.useMixpanel) {
      window.mixpanel.track('Order Active List Page')
    }
  }

  orderPageViewed(params = {}) {
    if (this.useMixpanel) {
      window.mixpanel.track('Order Create Page', params)
    }
  }

  contactUs() {
    if (this.useMixpanel) {
      window.mixpanel.track('Contact an Advisor')
    }
  }

  crispChatMessage() {
    if (this.useMixpanel) {
      window.mixpanel.track('Contact by Crisp')
    }
  }

  userOrdered(params = {}) {
    if (this.useMixpanel) {
      window.mixpanel.track('Order Created', params)
    }
  }

  markerAdjustmentButton() {
    if (this.useMixpanel) {
      window.mixpanel.track('Marker Adjustment Button')
    }
  }
}

export default new Mixpanel()
