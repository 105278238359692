import $ from 'jquery'

import { CALL_API } from 'middleware/api'

let merchantActions = {}

merchantActions.CLEAR_AVAILABLE_FILTERS = 'CLEAR_AVAILABLE_FILTERS'

const genTypeActions = (entity, action) => {
  let arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  let arrType = arr.map(el => {
    let type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    merchantActions[type] = type
    return type
  })
  return arrType
}

export function createMerchant(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `merchant/create/`,
      authenticated: false,
      data: data,
      storeAPI: true,
      register: true,
      types: genTypeActions('merchant', 'create'),
    },
  }
}

export function retrieveMerchant() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `merchant/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('merchant', 'retrieve'),
    },
  }
}

export function publicMerchant(slug) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `merchant/public/${slug}/`,
      authenticated: false,
      storeAPI: true,
      types: genTypeActions('merchant', 'public'),
    },
  }
}

export function updateMerchant(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `merchant/update/`,
      authenticated: true,
      data: data,
      storeAPI: true,
      types: genTypeActions('merchant', 'update'),
    },
  }
}

export function updateFBMerchant(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `merchant/facebook/`,
      authenticated: true,
      data: data,
      storeAPI: true,
      types: genTypeActions('merchant', 'facebook'),
    },
  }
}

export function retrieveFacebook(data) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `merchant/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('facebook', 'retrieve'),
    },
  }
}

export function listMerchantType() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `merchant-type/`,
      authenticated: false,
      storeAPI: true,
      types: genTypeActions('merchanttype', 'list'),
    },
  }
}

export function cancelOrder(id) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `order/${id}/cancel/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('order', 'cancel'),
    },
  }
}

export function confirmOrder(id, data) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `order/${id}/confirm/`,
      authenticated: true,
      storeAPI: true,
      data: data,
      types: genTypeActions('order', 'delivery'),
    },
  }
}

export function readyOrder(id) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `order/${id}/ready/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('order', 'ready'),
    },
  }
}

export function completeOrder(id) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `order/${id}/complete/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('order', 'complete'),
    },
  }
}

export function listOrder(params = {}) {
  let url = `order/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('order', 'list'),
    },
  }
}

export function listOrderHistoric(params = {}) {
  let url = `order/historic/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('order', 'historic'),
    },
  }
}

export function retrieveOrder(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `order/${id}/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('order', 'retrieve'),
    },
  }
}

export function retrieveOrderPending() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `merchant/pending/orders/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('order', 'pending'),
    },
  }
}

export function filterOrder(data) {
  return function(dispatch) {
    const action = {
      type: 'ORDER_FILTER_UPDATE',
      data: data,
    }
    dispatch(action)
  }
}

export function filterHistoric(data) {
  return function(dispatch) {
    const action = {
      type: 'HISTORY_FILTER_UPDATE',
      data: data,
    }
    dispatch(action)
  }
}

export function getHistoricAvailableFilters() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `order/historic/available-filters/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('historic', 'available_filters'),
    },
  }
}

export function getOrderAvailableFilters() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `order/available-filters/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('order', 'available_filters'),
    },
  }
}

export function clearAvailableFilters() {
  return function(dispatch) {
    const action = {
      type: merchantActions.CLEAR_AVAILABLE_FILTERS,
    }
    dispatch(action)
  }
}

export function filterIncomes(data) {
  return function(dispatch) {
    const action = {
      type: 'INCOMES_FILTER_UPDATE',
      data: data,
    }
    dispatch(action)
  }
}

export function retrieveDelivery(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `orders/${id}/`,
      authenticated: true,
      types: genTypeActions('delivery', 'retrieve'),
    },
  }
}

export function createSchedule(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `merchant/schedule/create/`,
      authenticated: true,
      data: data,
      storeAPI: true,
      types: genTypeActions('schedule', 'create'),
    },
  }
}

export function listSchedule() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `merchant/schedule/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('schedule', 'list'),
    },
  }
}

export function deleteSchedule(data) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `merchant/schedule/active/`,
      authenticated: true,
      data: data,
      storeAPI: true,
      types: genTypeActions('schedule', 'delete'),
    },
  }
}

export function listIncomes(params = {}) {
  let url = `order/merchant/complete/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('incomes', 'list'),
    },
  }
}
export function totalIncomes(params = {}) {
  let url = `order/merchant/total/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('incomes', 'total'),
    },
  }
}

export default merchantActions
