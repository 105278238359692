import {
  CLIENT_PAYMENTS_SUCCESS,
  CLIENT_PAYMENTS_REQUEST,
  CLIENT_PAYMENTS_FAILURE,
} from 'actions/clientActions'
import { ClientPaymentsState, ClientPaymentsActionTypes } from './types'

const initialState: ClientPaymentsState = {
  isFetching: false,
  data: undefined,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  action: ClientPaymentsActionTypes,
) {
  switch (action.type) {
    case CLIENT_PAYMENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case CLIENT_PAYMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data.results,
      }

    case CLIENT_PAYMENTS_FAILURE:
      return { ...state, errors: action.data }

    default:
      return state
  }
}
