import merchantActions from 'actions/merchantActions'

export function merchant(
  state = {
    isFetching: false,
    data: [],
    filters: {
      page: 1,
    },
    availableFilters: {},
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case merchantActions.MERCHANT_CREATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errors: {},
      })
    case merchantActions.MERCHANT_CREATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    case merchantActions.MERCHANT_CREATE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    case merchantActions.MERCHANT_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errors: {},
      })
    case merchantActions.MERCHANT_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    case merchantActions.MERCHANT_UPDATE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    case merchantActions.MERCHANT_FACEBOOK_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errors: {},
      })
    case merchantActions.MERCHANT_FACEBOOK_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case merchantActions.ORDER_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case merchantActions.ORDER_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        filters: { page: 1 },
      })

    case merchantActions.ORDER_AVAILABLE_FILTERS_REQUEST:
    case merchantActions.HISTORIC_AVAILABLE_FILTERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case merchantActions.ORDER_AVAILABLE_FILTERS_SUCCESS:
    case merchantActions.HISTORIC_AVAILABLE_FILTERS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        availableFilters: action.data,
      })
    case merchantActions.ORDER_AVAILABLE_FILTERS_FAILURE:
    case merchantActions.HISTORIC_AVAILABLE_FILTERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: {},
      })

    case merchantActions.CLEAR_AVAILABLE_FILTERS:
      return Object.assign({}, state, {
        availableFilters: {},
      })

    default:
      return state
  }
}

export function retrieveMerchant(
  state = {
    isFetching: false,
    data: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case merchantActions.MERCHANT_RETRIEVE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })

    case merchantActions.MERCHANT_RETRIEVE_SUCCESS:
      action.data.coordinates = `${action.data.latlon.coordinates[1]}, ${
        action.data.latlon.coordinates[0]
      }`
      action.data.latlon = {
        coordinates: action.data.coordinates,
        name: action.data.address,
      }
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case merchantActions.MERCHANT_PUBLIC_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case merchantActions.MERCHANT_PUBLIC_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    default:
      return state
  }
}

export function retrieveFacebook(
  state = {
    isFetching: false,
    data: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case merchantActions.FACEBOOK_RETRIEVE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case merchantActions.FACEBOOK_RETRIEVE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    default:
      return state
  }
}

export function schedule(
  state = {
    isFetching: false,
    data: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case merchantActions.SCHEDULE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case merchantActions.SCHEDULE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case 'FBC':
      return Object.assign({}, state, {
        isFetching: true,
        type: action.payload,
      })

    default:
      return state
  }
}

export function createSchedule(
  state = {
    isFetching: false,
    data: [],
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case merchantActions.MERCHANT_CREATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errors: {},
      })
    case merchantActions.MERCHANT_CREATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    case merchantActions.MERCHANT_CREATE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })
    default:
      return state
  }
}

export function listMerchantType(
  state = {
    isFetching: false,
    data: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case merchantActions.MERCHANTTYPE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case merchantActions.MERCHANTTYPE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    default:
      return state
  }
}
