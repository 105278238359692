import {
  MAP_KEY_REQUEST,
  MAP_KEY_SUCCESS,
  MAP_KEY_FAILURE,
} from 'actions/all/googleMapsKeyAction'

const initialState = {
  isFetching: false,
  key: '',
  errors: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MAP_KEY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case MAP_KEY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        key: action.data,
      })
    case MAP_KEY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    default:
      return state
  }
}
