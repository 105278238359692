import orderActions, {
  ORDER_DELIVERY_WINDOWS_REQUEST,
  ORDER_DELIVERY_WINDOWS_SUCCESS,
  ORDER_DELIVERY_WINDOWS_FAILURE,
} from '../../actions/orderActions'
import { WS_MESSAGE } from '../../actions/wsActions'
import ratingActions from '../../actions/raitingActions'

let ORDER_CALCULATE_PRICE_RESUME_SUCCESS =
  'ORDER_CALCULATE_PRICE_RESUME_SUCCESS'

export default function reducer(
  state = {
    isFetching: false,
    data: false,
    extraData: {},
    draftData: false,
    errors: {},
    relaunchData: {},
    nextStep: false,
    reloadDetail: false,
    getDataOrder: false,
    draftDataSuccess: false,
    ratingUpdate: false,
    stayInFirstStep: false,
    isMassiveOrder: false,
    massiveOrderData: [],
    individualData: [],
    courierNearby: false,
    delivery_windows: [],
  },
  action,
) {
  switch (action.type) {
    case orderActions.COURIER_NEARBY_SUCCESS:
      return Object.assign({}, state, {
        courierNearby: action.data,
      })

    case orderActions.ORDER_MASSIVE:
      return Object.assign({}, state, {
        isMassiveOrder: true,
        massiveOrderData: [],
      })

    case orderActions.ORDER_MASSIVE_DATA:
      return Object.assign({}, state, {
        massiveOrderData: action.data.data,
        individualData: action.data.individual_data,
        isMassiveOrder: false,
      })

    case orderActions.ORDER_CALCULATE_PRICE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        getDataOrder: false,
        draftDataSuccess: false,
        stayInFirstStep: !action.extra.nextStep,
      })

    case orderActions.ORDER_CALCULATE_PRICE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data.prices,
        extraData: action.data,
        nextStep: !state.stayInFirstStep,
      })

    case ORDER_CALCULATE_PRICE_RESUME_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data.prices,
        extraData: action.data,
        nextStep: true,
      })

    case orderActions.ORDER_CALCULATE_PRICE_MASSIVE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        getDataOrder: false,
        draftDataSuccess: false,
        stayInFirstStep: !action.extra.nextStep,
      })

    case orderActions.ORDER_CALCULATE_PRICE_MASSIVE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data.reduce((acc, el) => {
          acc.push(el.prices[0].price)
          return acc
        }, []),
        nextStep: state.stayInFirstStep ? false : true,
      })

    case orderActions.ORDER_CALCULATE_PRICE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    case orderActions.ORDER_PREVIUSSTEP_CLICK:
      return Object.assign({}, state, { nextStep: false })

    case orderActions.CLEAR_ORDER:
      return Object.assign({}, state, {
        extraData: {},
        draftData: false,
        errors: {},
        relaunchData: {},
        nextStep: false,
        reloadDetail: false,
        getDataOrder: false,
        data: false,
        stayInFirstStep: false,
        massiveOrderData: [],
      })

    case orderActions.ORDER_DRAFTGETDATA_SUCCESS:
      if (action.data.destinations && action.data.destinations.length) {
        action.data.destinations = action.data.destinations.map(el => {
          if (el) {
            if (el.latlon) {
              const parseLatlon = {
                coordinates: el.latlon,
                name: el.name_destination,
              }
              el.latlon = parseLatlon
            }
            return el
          } else {
            return {}
          }
        })
      }
      action.data.type = `${action.data.order_type || action.data.type}`
      action.data.vehicle_type = {
        id: `${action.data.vehicle_type || action.data.vehicle_type_id}`,
      }
      action.data.destinations.forEach((item, index) => {
        if (item.package_type_id) {
          item.package_type = { id: item.package_type_id }
        }
      })
      return Object.assign({}, state, {
        isFetching: false,
        draftData: action.data,
      })

    case WS_MESSAGE:
      const id = window.location.pathname.match(/\d+/)
      const getId = id ? id.pop() : false
      if (
        action.entity === 'ORDER_DETAIL' &&
        getId &&
        action.data.payload &&
        Number(action.data.payload.id) === Number(getId)
      ) {
        return Object.assign({}, state, { reloadDetail: true })
      } else {
        return Object.assign({}, state, { otherWS: action.data })
      }

    case orderActions.ORDER_GETDATA_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        relaunchData: undefined,
      })

    case orderActions.ORDER_GETDATA_SUCCESS:
      action.data.destinations = action.data.destinations.map(el => {
        const parseLatlon = {
          coordinates: el.latlon,
          name: el.name_destination,
        }
        el.latlon = parseLatlon
        return el
      })
      action.data.type = `${action.data.type}`
      action.data.vehicle_type.id = `${action.data.vehicle_type.id}`

      // Verificamos si la URL es diferente del patrón `/detalle/pedido/{orderId}`
      const pathPattern = /^\/detalle\/pedido\/\d+$/ // Expresión regular para `/detalle/pedido/{orderId}`
      const isDifferentOrderDetailPage = !pathPattern.test(
        window.location.pathname,
      ) // Ahora validamos que NO coincida

      if (action.data.is_return && isDifferentOrderDetailPage) {
        action.data.destinations.pop()
      }
      return Object.assign({}, state, {
        isFetching: false,
        reloadDetail: false,
        relaunchData: action.data,
        getDataOrder: true,
      })

    case orderActions.DRAFT_SAVE_SUCCESS:
      window.location = '/borradores'
      return Object.assign({}, state, { draftDataSuccess: action.data })

    case ratingActions.UPDATE_RAITING_REQUEST:
      return Object.assign({}, state, { reloadDetail: false })

    case ratingActions.UPDATE_RAITING_SUCCESS:
      return Object.assign({}, state, { reloadDetail: true })

    case orderActions.UPDATE_PRICE:
      return Object.assign({}, state, {
        data: [
          { price: action.price, vehicle_type: 1 },
          { price: action.price, vehicle_type: 2 },
          { price: action.price, vehicle_type: 3 },
        ],
      })

    case ORDER_DELIVERY_WINDOWS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case ORDER_DELIVERY_WINDOWS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        delivery_windows: action.data.results,
      })
    case ORDER_DELIVERY_WINDOWS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      })

    default:
      return state
  }
}
