export const WS_CONNECT = 'WS_CONNECT'

export const WS_CONNECT_SUCCESS = 'WS_CONNECT_SUCCESS'
export const WS_CONNECT_FAILURE = 'WS_CONNECT_FAILURE'
export const WS_MESSAGE = 'WS_MESSAGE'

let ws
export function wsEvents(key, query, type) {
  return dispatch => {
    dispatch({
      type: `${WS_CONNECT}$`,
      entity: type,
    })
    ws = new WebSocket(
      `${process.env.REACT_APP_BASE_WS_URL}ws/client/?${query}=${key}`,
    )
    ws.onopen = () => {
      dispatch({
        type: WS_CONNECT_SUCCESS,
        entity: type,
      })
    }
    ws.onmessage = e => {
      dispatch({
        type: WS_MESSAGE,
        entity: type,
        data: JSON.parse(e.data),
      })
    }
  }
}

let wst
export function wsTrackingOrder(key, query, type) {
  return dispatch => {
    dispatch({
      type: `${WS_CONNECT}$`,
      entity: type,
    })
    wst = new WebSocket(
      `${process.env.REACT_APP_BASE_WS_URL}ws/order/?${query}=${key}`,
    )
    wst.onopen = () => {
      dispatch({
        type: WS_CONNECT_SUCCESS,
        entity: type,
      })
    }
    wst.onmessage = e => {
      dispatch({
        type: WS_MESSAGE,
        entity: type,
        data: JSON.parse(e.data),
      })
    }
  }
}

export function closeWS(type) {
  return dispatch => {
    dispatch({
      type: `WS_CLOSE`,
      entity: type,
    })
    if (ws instanceof WebSocket) {
      ws.close()
    }
  }
}

let wsTracker
export function wsEventsTracker(key, query, type) {
  return dispatch => {
    dispatch({
      type: `${WS_CONNECT}$`,
      entity: type,
    })
    wsTracker = new WebSocket(
      `${process.env.REACT_APP_TRACKER_WS}?${query}=${key}`,
    )
    wsTracker.onopen = () => {
      dispatch({
        type: WS_CONNECT_SUCCESS,
        entity: type,
      })
    }
    wsTracker.onmessage = e => {
      dispatch({
        type: WS_MESSAGE,
        entity: type,
        data: JSON.parse(e.data),
      })
    }
  }
}

export function closeWSTracker(type) {
  return dispatch => {
    dispatch({
      type: `WS_CLOSE`,
      entity: type,
    })
    if (wsTracker instanceof WebSocket) {
      wsTracker.close()
    }
  }
}
