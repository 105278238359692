import { SEND_DATA } from 'actions/courierActions'

export default function reducer(
  state = {
    type: '',
    extra: '',
  },
  action,
) {
  switch (action.type) {
    case SEND_DATA:
      return Object.assign({}, state, {
        type: action.payload,
        extra: action.extra,
      })
    default:
      return state
  }
}
