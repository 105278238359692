import { CALL_API } from 'middleware/api'

export const GET_COURIER_NEARBY_REQUEST = 'GET_COURIER_NEARBY_REQUEST'
export const GET_COURIER_NEARBY_SUCCESS = 'GET_COURIER_NEARBY_SUCCESS'
export const GET_COURIER_NEARBY_FAILURE = 'GET_COURIER_NEARBY_FAILURE'

export function getNearbyCouriers(data: {
  latitude: number
  longitude: number
  radius: number
  limit: number
}) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'couriers/search',
      authenticated: false,
      data,
      isTracking: true,
      types: [
        GET_COURIER_NEARBY_REQUEST,
        GET_COURIER_NEARBY_SUCCESS,
        GET_COURIER_NEARBY_FAILURE,
      ],
    },
  }
}
