import { CALL_API } from 'middleware/api'

let trackingActions = {}

const genTypeActions = (entity, action) => {
  let arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  let arrType = arr.map(el => {
    let type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    trackingActions[type] = type
    return type
  })
  return arrType
}

export function getDataTracking(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `api/tracking/${id}/`,
      authenticated: false,
      isPublic: true,
      types: genTypeActions('tracking', 'getdata'),
    },
  }
}

export function getPolyline(waypoints) {
  let url = 'polyline/'
  if (waypoints) {
    url += '?'
    for (let wp of waypoints) {
      url = url.concat('waypoints=' + wp + '&')
    }
  }
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url.substring(0, url.length - 1),
      authenticated: true,
      types: genTypeActions('polyline', ''),
    },
  }
}

genTypeActions('tracking', 'getdata')
genTypeActions('polyline', '')
export default trackingActions
