import { CALL_API } from 'middleware/api'

export const MAP_KEY_REQUEST = 'MAP_KEY_REQUEST'
export const MAP_KEY_SUCCESS = 'MAP_KEY_SUCCESS'
export const MAP_KEY_FAILURE = 'MAP_KEY_FAILURE'

export function getGoogleMapsKey() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `api/key/`,
      authenticated: true,
      isPublic: true,
      types: [MAP_KEY_REQUEST, MAP_KEY_SUCCESS, MAP_KEY_FAILURE],
    },
  }
}
