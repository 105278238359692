import { CALL_API } from 'middleware/api'

let orderActions = {}

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE'

export const ORDER_TYPES_REQUEST = 'ORDER_TYPES_REQUEST'
export const ORDER_TYPES_SUCCESS = 'ORDER_TYPES_SUCCESS'
export const ORDER_TYPES_FAILURE = 'ORDER_TYPES_FAILURE'

export const ORDER_PRICE_REQUEST = 'ORDER_PRICE_REQUEST'
export const ORDER_PRICE_SUCCESS = 'ORDER_PRICE_SUCCESS'
export const ORDER_PRICE_FAILURE = 'ORDER_PRICE_FAILURE'

export const ORDER_DELIVERY_WINDOWS_REQUEST = 'ORDER_DELIVERY_WINDOWS_REQUEST'
export const ORDER_DELIVERY_WINDOWS_SUCCESS = 'ORDER_DELIVERY_WINDOWS_SUCCESS'
export const ORDER_DELIVERY_WINDOWS_FAILURE = 'ORDER_DELIVERY_WINDOWS_FAILURE'

export const NEW_SAVE_DRAFT_REQUEST = 'NEW_SAVE_DRAFT_REQUEST'
export const NEW_SAVE_DRAFT_SUCCESS = 'NEW_SAVE_DRAFT_SUCCESS'
export const NEW_SAVE_DRAFT_FAILURE = 'NEW_SAVE_DRAFT_FAILURE'

export const OPTIMIZE_DESTINATIONS_REQUEST = 'OPTIMIZE_DESTINATIONS_REQUEST'
export const OPTIMIZE_DESTINATIONS_SUCCESS = 'OPTIMIZE_DESTINATIONS_SUCCESS'
export const OPTIMIZE_DESTINATIONS_FAILURE = 'OPTIMIZE_DESTINATIONS_FAILURE'

export const CHECK_DANGER_COVERAGE_REQUEST = 'CHECK_DANGER_COVERAGE_REQUEST'
export const CHECK_DANGER_COVERAGE_SUCCESS = 'CHECK_DANGER_COVERAGE_SUCCESS'
export const CHECK_DANGER_COVERAGE_FAILURE = 'CHECK_DANGER_COVERAGE_FAILURE'

orderActions.ORDER_PREVIUSSTEP_CLICK = 'ORDER_PREVIUSSTEP_CLICK'
orderActions.CLEAR_ORDER = 'CLEAR_ORDER'
orderActions.UPDATE_PRICE = 'UPDATE_PRICE'
orderActions.ORDER_MASSIVE = 'ORDER_MASSIVE'
orderActions.ORDER_MASSIVE_DATA = 'ORDER_MASSIVE_DATA'

const genTypeActions = (entity, action) => {
  const arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  const arrType = arr.map(el => {
    const type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    orderActions[type] = type
    return type
  })
  return arrType
}

export function createOrder(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'orders/',
      authenticated: true,
      data,
      types: [CREATE_ORDER_REQUEST, CREATE_ORDER_SUCCESS, CREATE_ORDER_FAILURE],
    },
  }
}

export function getOrderTypes(data, nextStep) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `order_types/`,
      authenticated: true,
      data: data,
      extra: nextStep,
      msg: 'order',
      types: [ORDER_TYPES_REQUEST, ORDER_TYPES_SUCCESS, ORDER_TYPES_FAILURE],
    },
  }
}

export function getOrderPrice(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `order_types/${data.id}/calculate_price/`,
      authenticated: true,
      data,
      types: [ORDER_PRICE_REQUEST, ORDER_PRICE_SUCCESS, ORDER_PRICE_FAILURE],
    },
  }
}

export function oldGetOrderPrice(data, nextStep) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `order_types/${data.id}/calculate_price/`,
      authenticated: true,
      data: data,
      extra: nextStep,
      msg: 'order',
      types: genTypeActions('order', 'calculate_price'),
    },
  }
}

export function getOrderPriceResume(data, nextStep) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `order_types/${data.id}/calculate_price/`,
      authenticated: true,
      data: data,
      extra: nextStep,
      msg: 'order',
      types: genTypeActions('order', 'calculate_price_resume'),
    },
  }
}

export function getOrderPriceMassive(data, nextStep) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `price/massive/`,
      authenticated: true,
      data: data,
      extra: nextStep,
      msg: 'order',
      types: genTypeActions('order', 'calculate_price_massive'),
    },
  }
}

export function getOrderFromExcel(data, nextStep) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `parse/excel/`,
      authenticated: true,
      data: data,
      extra: nextStep,
      types: genTypeActions('order', 'get_from_excel'),
    },
  }
}

export function getOrderData(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `orders/${id}/`,
      authenticated: true,
      types: genTypeActions('order', 'getdata'),
    },
  }
}

export function getDraftOrderData(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `drafts/${id}/`,
      authenticated: true,
      types: genTypeActions('order', 'draftgetdata'),
    },
  }
}

export function getDeliveryWindows() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `order_types/delivery_windows_all/`,
      authenticated: true,
      types: [
        ORDER_DELIVERY_WINDOWS_REQUEST,
        ORDER_DELIVERY_WINDOWS_SUCCESS,
        ORDER_DELIVERY_WINDOWS_FAILURE,
      ],
    },
  }
}

export function orderPreviusClick() {
  return function(dispatch) {
    const action = {
      type: orderActions.ORDER_PREVIUSSTEP_CLICK,
    }
    dispatch(action)
  }
}

export function orderMassive() {
  return function(dispatch) {
    const action = {
      type: orderActions.ORDER_MASSIVE,
    }
    dispatch(action)
  }
}

export function orderMassiveData(data) {
  return function(dispatch) {
    const action = {
      type: orderActions.ORDER_MASSIVE_DATA,
      data,
    }
    dispatch(action)
  }
}

export function cancelOrder(id, data) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `orders/${id}/cancel/`,
      authenticated: true,
      data: data ? data : null,
      types: genTypeActions('order', 'cancel'),
      extra: id,
    },
  }
}

export function saveDraft(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `drafts/`,
      authenticated: true,
      data: data,
      types: genTypeActions('draft', 'save'),
    },
  }
}

export function newSaveDraft(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `drafts/`,
      authenticated: true,
      data,
      types: [
        NEW_SAVE_DRAFT_REQUEST,
        NEW_SAVE_DRAFT_SUCCESS,
        NEW_SAVE_DRAFT_FAILURE,
      ],
    },
  }
}

export function clearOrder() {
  return function(dispatch) {
    const action = {
      type: orderActions.CLEAR_ORDER,
    }
    dispatch(action)
  }
}

export function updatePrice(value) {
  return function(dispatch) {
    const action = {
      type: orderActions.UPDATE_PRICE,
      price: value,
    }
    dispatch(action)
  }
}

export function updateDestinationsOrder(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `optimize/points/`,
      authenticated: true,
      data: data,
      types: genTypeActions('order', 'optimze'),
    },
  }
}

export function optimizeDestinations(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `optimize/points/`,
      authenticated: true,
      data: data,
      types: [
        OPTIMIZE_DESTINATIONS_REQUEST,
        OPTIMIZE_DESTINATIONS_SUCCESS,
        OPTIMIZE_DESTINATIONS_FAILURE,
      ],
    },
  }
}

export function getCourierNearby(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `area/couriers/`,
      authenticated: true,
      data: data,
      types: genTypeActions('courier', 'nearby'),
    },
  }
}

export function checkDangerCoverage(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `check/danger/coverage/`,
      authenticated: true,
      data: data,
      types: [
        CHECK_DANGER_COVERAGE_REQUEST,
        CHECK_DANGER_COVERAGE_SUCCESS,
        CHECK_DANGER_COVERAGE_FAILURE,
      ],
    },
  }
}

genTypeActions('draft', 'save')
genTypeActions('order', 'calculate_price')
genTypeActions('order', 'getdata')
genTypeActions('order', 'cancelOrder')
genTypeActions('order', 'draftgetdata')
genTypeActions('order', 'get_from_excel')
genTypeActions('order', 'calculate_price_massive')
genTypeActions('courier', 'nearby')
export default orderActions
