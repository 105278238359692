import { createGlobalStyle } from 'styled-components'

import typography from 'utils/css/typography'
import { colors } from 'utils/css/variables'

const fontFamily = typography.options.bodyFontFamily
  ? typography.options.bodyFontFamily.join(', ')
  : 'sans-serif, arial'

export default createGlobalStyle`
  *, *:before, *:after {
		box-sizing: border-box;
		outline:none;
	}
	html {
		overflow: hidden auto;
	}
	html,
	body {
		font-family: ${fontFamily};
		font-size: ${typography.options.baseFontSize};
		color: ${typography.options.bodyColor};
		display: inline-block;
		width:100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}
	#root {
		min-height: 100%;
    min-width: 100%;
	}
	#root.gray-bg {
		background-color: ${colors.lima_gray};
	}
		
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	a {
		text-decoration:none;
		color:white;
	}
	a:hover, a:focus {
		text-decoration:none;
	}
	a, button, input[type="button"] {
		cursor: pointer;
	}
	input {
		border:none;
		font-size:16px !important;
		background:transparent;
	}

	select {
		background: white;
    border-radius: 3px;
    border: none;
    padding:8px 26px;
	}
	input[type='text']{
		&:focus{ outline:none; border:none; }
		&:focus~label, &:valid~label{
			font-size:14px;
			color:${colors.sky_blue};

		}
		/* active state */
		&:focus~span:before, &:focus~span:after {
			width:50%;
			background:${colors.sky_blue}
		}
	}

	img {
		margin: 0;
		max-width: unset;
	}

	/* Material Inputs */

	.mdc-floating-label {
		font-family: ${fontFamily} !important;
	}
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
		border-color: ${colors.border} !important;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
		stroke: ${colors.border} !important;
	}

	.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
		border-bottom-color: ${colors.border} !important;
	}

  .mdc-text-field:not(.mdc-text-field--disabled),
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
		background-color: transparent !important;
	}
  .mdc-text-field:hover::before {
    opacity: 0 !important;
  }
	.mdc-text-field .mdc-custom-input__primary {
		caret-color: ${colors.green} !important;
  }
  .mdc-text-field .mdc-custom-input__secondary {
		caret-color: ${colors.sky_blue} !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-custom-input__primary:hover ~ .mdc-notched-outline__idle,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-custom-input__primary:hover ~ .mdc-notched-outline__idle {
    border-color: ${colors.green} !important;
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-custom-input__secondary:hover ~ .mdc-notched-outline__idle,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-custom-input__secondary:hover ~ .mdc-notched-outline__idle {
    border-color: ${colors.sky_blue} !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-custom-outline__primary .mdc-notched-outline__path,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-custom-outline__primary .mdc-notched-outline__path {
    stroke: ${colors.green} !important;
  } 
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-custom-outline__secondary .mdc-notched-outline__path,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-custom-outline__secondary .mdc-notched-outline__path {
    stroke: ${colors.sky_blue} !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-floating-label.mdc-custom-label__primary,
	.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label.mdc-custom-label__primary {
		color: ${colors.green};
	}
	
	.mdc-select--focused:not(.mdc-select--disabled) .mdc-floating-label.mdc-custom-label__primary {
		color: ${colors.green} !important;
	}

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-floating-label.mdc-custom-label__secondary,
	.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label.mdc-custom-label__secondary {
    color: ${colors.sky_blue};
	}

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-custom-outline__primary .mdc-notched-outline__path {
    stroke: ${colors.green} !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-custom-outline__secondary .mdc-notched-outline__path {
    stroke: ${colors.sky_blue} !important;
  }

  .mdc-line-ripple.mdc-custom-ripple__primary {
    background-color: ${colors.green} !important;
  }

  .mdc-line-ripple.mdc-custom-ripple__secondary {
    background-color: ${colors.sky_blue} !important;
	}
	
	.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
		border-bottom-color: ${colors.error_color} !important;
	}

	.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
		border-bottom-color: ${colors.error_color} !important;
	}

	.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple {
		background-color: ${colors.error_color} !important;
	}

	.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
		color: ${colors.error_color} !important;
	}

	.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::-webkit-input-placeholder {
		color: ${colors.error_color} !important;
	}

	.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
		color: ${colors.error_color} !important;
	}

	.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::-ms-input-placeholder {
		color: ${colors.error_color} !important;
	}

	.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
		color: ${colors.error_color} !important;
	}

	.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
		color: ${colors.error_color} !important;
	}

	.mdc-text-field--invalid .mdc-text-field__input {
		caret-color: ${colors.error_color} !important;
	}

	.mdc-text-field--invalid.mdc-text-field--with-trailing-icon:not(.mdc-text-field--with-leading-icon):not(.mdc-text-field--disabled) .mdc-text-field__icon {
		color: ${colors.error_color} !important;
	}

	.mdc-text-field--invalid.mdc-text-field--with-trailing-icon.mdc-text-field--with-leading-icon:not(.mdc-text-field--disabled) .mdc-text-field__icon ~ .mdc-text-field__icon {
		color: ${colors.error_color} !important;
	}

	.mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
		opacity: 1;
	}

	.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
		border-color: ${colors.error_color} !important;
	}
	.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
		border-color: ${colors.error_color} !important;
	}

	.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
		border-color: ${colors.error_color} !important;
	}

	.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
		stroke: ${colors.error_color} !important;
	}

	.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle,
	.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline__idle {
		border-color: ${colors.error_color} !important;
	}

	.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path,
	.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
		stroke: ${colors.error_color} !important;
	}

	.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__path {
		stroke: ${colors.error_color} !important;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-custom-outline__primary .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-custom-outline__primary .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-custom-outline__primary .mdc-notched-outline__trailing,
	.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-custom-outline__primary .mdc-notched-outline__leading,
	.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-custom-outline__primary .mdc-notched-outline__notch,
	.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-custom-outline__primary .mdc-notched-outline__trailing{
		border-color: ${colors.green} !important;	
	}

	.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
	.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
	.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
		border-color: ${colors.error_color} !important;	
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-custom-outline__secondary .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-custom-outline__secondary .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-custom-outline__secondary .mdc-notched-outline__trailing,
	.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-custom-outline__secondary .mdc-notched-outline__leading,
	.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-custom-outline__secondary .mdc-notched-outline__notch,
	.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-custom-outline__secondary .mdc-notched-outline__trailing{
		border-color: ${colors.sky_blue};	
	}

	.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-notched-outline .mdc-notched-outline__leading,
	.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-notched-outline .mdc-notched-outline__notch,
	.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-notched-outline .mdc-notched-outline__trailing{
		border-top: 1.5px solid !important;
    	border-bottom: 1.5px solid !important;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-notched-outline .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-notched-outline .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-notched-outline .mdc-notched-outline__trailing,
	.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-notched-outline .mdc-notched-outline__leading,
	.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-notched-outline .mdc-notched-outline__notch,
	.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-notched-outline .mdc-notched-outline__trailing{
		border-color: ${colors.border} !important;
	}

	.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label.mdc-floating-label--float-above{
		left: 22px;
	}

	.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea):not(.mdc-text-field--focused)  .mdc-text-field__input,
	.mdc-select:not(.mdc-select--disabled):not(.mdc-select--outlined):not(.mdc-select--focused) .mdc-select__native-control{
		border-bottom: 1.5px solid ${colors.border} !important;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-notched-outline__leading, 
	.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-notched-outline__leading{
		border-left: 1.5px solid !important;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-notched-outline__trailing{
		border-top: 1.5px solid !important;
    	border-bottom: 1.5px solid !important;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-notched-outline__trailing, 
	.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-notched-outline__trailing{
		border-right: 1.5px solid !important;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-notched-outline--notched .mdc-notched-outline__notch,
	.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-notched-outline--notched .mdc-notched-outline__notch{
		border-top: none !important;	
	}

	.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea).mdc-text-field--focused .mdc-custom-input__primary,
	.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea).mdc-text-field--focused .mdc-custom-input__primary:hover {
		background-color: #e8fff5 !important;
	}

	.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea).mdc-text-field--focused .mdc-custom-input__secondary,
	.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea).mdc-text-field--focused .mdc-custom-input__secondary:hover {
		background-color: #edebff !important;
	}

	.mdc-select.input-bg-white .mdc-notched-outline.mdc-notched-outline--upgraded,
	select.input-bg-white,
	.mdc-text-field.input-bg-white .mdc-text-field__input {
		background-color: #fff;
	}

	.mdc-select {
		width: 100%;
	}

	.mdc-text-field.input-bg-white .mdc-notched-outline.mdc-notched-outline--upgraded {
		z-index: 1;
	}

	/* HelperText */
	.mdc-text-field + .mdc-text-field-helper-line {
		display: none;
	}
	.mdc-text-field--focused + .mdc-text-field-helper-line {
		display: block;
	}
	.mdc-text-field-helper-line {
		text-align: left;
	}

	/* React Datepicker */
	.react-datepicker__input-container input{
		background: transparent;
		border: none !important;
		border-radius: 0;
	}

	/* React Tab */
	.react-tabs__tab {
		margin: 0;
	}
	.react-tabs__tab img {
		margin-right: 16px;
		vertical-align: middle;
	}
	.react-tabs__tab span{
		vertical-align: middle;
	}
	.react-tabs__tab--selected {
		border: none !important;
		border-bottom: 2px solid ${colors.green} !important;
	}
	.react-tabs__tab-list {
		border-bottom-color: ${colors.border} !important;
		margin-bottom: 20px !important;
	}
	.react-tabs__tab-list li:first-child{
		padding-left: 0
	}

	/* material icons */
	.material-icons.md-19 { font-size: 19px; }
	.material-icons.md-18 { font-size: 18px; }

	/* Material Switch */
	.mdc-switch+label{
		margin-left: 10px;
	}

	.mdc-switch__track {
		background: rgba(131, 142, 171, 0.5) !important;
		border: none !important;
		opacity: 1 !important;
	}

	.mdc-switch__thumb {
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24) !important;
		border: solid 0.5px rgba(0, 0, 0, 0) !important;
		background-color: ${colors.border} !important;
	}

	.mdc-switch--checked .mdc-switch__thumb {
		background-color: ${colors.green} !important;
	}

	.mdc-switch--checked .mdc-switch__track {
		background-color: ${colors.light_green} !important;
	}

	/* Datepicker */
	.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__time-list-item--selected{
		background-color: ${colors.green} !important;
	}
	.react-datepicker__day:hover, .react-datepicker__time-list-item:hover{
		background-color: ${colors.light_green} !important;
	}	
	.react-datepicker__day--selected:hover, .react-datepicker__day--keyboard-selected:hover, .react-datepicker__time-list-item--selected:hover{
		background-color: ${colors.green} !important;
	}
	.react-datepicker__day--disabled:hover, .react-datepicker__time-list-item--disabled:hover{
		background-color: transparent !important;
	}

	/*React Checkbox */
	.mdc-checkbox::before, .mdc-checkbox::after { 
		background-color: ${colors.green} !important;
	}
	.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, 
	.mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
		background-color: ${colors.green} !important;
		border-color: ${colors.green} !important;
	}
	.mdc-checkbox__background::before {
		background-color: ${colors.green} !important;
	}
	@keyframes mdc-checkbox-fade-in-background-custom {
		0% {
			border-color: rgba(0, 0, 0, 0.54);
			background-color: transparent; }
		50% {
			border-color: ${colors.green};
			background-color: ${colors.green};
		} 
	}
	@keyframes mdc-checkbox-fade-out-background-custom {
		0%,
		80% {
			border-color: ${colors.green};
			background-color: ${colors.green};
		}
		100% {
			border-color: rgba(0, 0, 0, 0.54);
			background-color: transparent;
		} 
	}
	.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, 
	.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
		animation-name: mdc-checkbox-fade-in-background-custom !important; 
	}
	.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, 
	.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
		animation-name: mdc-checkbox-fade-out-background-custom !important; 
	}

	/* React Tel */
	.react-tel-input .mdc-text-field--outlined.mdc-text-field + .flag-dropdown .selected-flag .flag {
		top: 35%;
	}
`
