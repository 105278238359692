import $ from 'jquery'

import { CALL_API } from 'middleware/api'

let productActions = {}

const genTypeActions = (entity, action) => {
  let arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  let arrType = arr.map(el => {
    let type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    productActions[type] = type
    return type
  })
  return arrType
}

export function createProduct(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `product/create/`,
      authenticated: true,
      data: data,
      storeAPI: true,
      types: genTypeActions('product', 'create'),
    },
  }
}

export function listProducts(params = {}) {
  let url = `product/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('product', 'list'),
    },
  }
}

export function updateProduct(data, id) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `product/${id}/update/`,
      authenticated: true,
      data: data,
      storeAPI: true,
      types: genTypeActions('product', 'update'),
    },
  }
}

export function retrieveProduct(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `product/${id}/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('product', 'retrieve'),
    },
  }
}

export function activeProduct(data) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `product/active/`,
      authenticated: true,
      data: data,
      storeAPI: true,
      types: genTypeActions('product', 'active'),
    },
  }
}

export function deleteProduct(data) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `product/delete/`,
      authenticated: true,
      data: data,
      storeAPI: true,
      types: genTypeActions('product', 'delete'),
    },
  }
}

export function excelProduct(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `product/create/excel/`,
      authenticated: true,
      data: data,
      storeAPI: true,
      types: genTypeActions('product', 'excel'),
    },
  }
}

export function filterProuct(data) {
  return function(dispatch) {
    const action = {
      type: 'PRODUCT_FILTER_UPDATE',
      data: data,
    }
    dispatch(action)
  }
}

export function createPromotion(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `promotion/create/`,
      authenticated: true,
      data: data,
      storeAPI: true,
      types: genTypeActions('promotion', 'create'),
    },
  }
}

export function listPromotion() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `promotion/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('promotion', 'list'),
    },
  }
}

export function updatePromotion(data, id) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `promotion/${id}/update/`,
      authenticated: true,
      data: data,
      storeAPI: true,
      types: genTypeActions('promotion', 'update'),
    },
  }
}

export function retrievePromotion(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `promotion/${id}/`,
      authenticated: true,
      storeAPI: true,
      types: genTypeActions('promotion', 'retrieve'),
    },
  }
}

export function deletePromotion(data) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `promotion/active/`,
      authenticated: true,
      data: data,
      storeAPI: true,
      types: genTypeActions('promotion', 'delete'),
    },
  }
}

genTypeActions('product', 'create')
genTypeActions('product', 'list')
genTypeActions('product', 'update')
genTypeActions('product', 'retrieve')
genTypeActions('product', 'excel')
genTypeActions('product', 'filter')

genTypeActions('promotion', 'create')
genTypeActions('promotion', 'list')
genTypeActions('promotion', 'update')
genTypeActions('promotion', 'retrieve')

export default productActions
