import _ from 'lodash'

import guestActions, {
  EDIT_GUEST,
  CLEAN_GUEST,
  OPEN_GUEST,
  UPDATE_GUESTS_FILTERS,
  CLEAN_ERRORS,
} from 'actions/guestActions'

export default function reducer(
  state = {
    isFetching: false,
    data: false,
    needRefresh: false,
    filters: {
      created_at_0: null,
      created_at_1: null,
      search: '',
      page: 1,
    },
    openForm: false,
    editGuest: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case guestActions.GUEST_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        favoriteAddress: false,
        needRefresh: false,
      })
    case guestActions.GUEST_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        filters: { page: action.data.current_page },
      })

    case guestActions.GUEST_DELETE_SUCCESS:
      return Object.assign({}, state, {
        editGuest: false,
        needRefresh: true,
        openForm: false,
      })
    case guestActions.GUEST_DELETE_FAILURE:
      return Object.assign({}, state, {
        errors: action.errors,
      })

    case UPDATE_GUESTS_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true,
      })

    case OPEN_GUEST:
      return Object.assign({}, state, {
        openForm: action.open,
      })

    case EDIT_GUEST:
      let tmp = _.clone(action.guest)
      return Object.assign({}, state, { editGuest: tmp, openForm: true })
    case CLEAN_GUEST:
      return Object.assign({}, state, { editGuest: false, openForm: false })

    case guestActions.GUEST_MASSIVE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
      })
    case guestActions.GUEST_MASSIVE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: true,
        errors: {},
      })
    case guestActions.GUEST_MASSIVE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    case CLEAN_ERRORS:
      return Object.assign({}, state, {
        errors: {},
      })

    default:
      return state
  }
}
