import productActions from 'actions/productActions'

export function listProducts(
  state = {
    isFetching: false,
    data: false,
    errors: {},
    reloadDetail: false,
    filters: {
      page: 1,
      search: '',
    },
    needRefresh: false,
  },
  action,
) {
  switch (action.type) {
    case productActions.PRODUCT_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        reloadDetail: false,
        needRefresh: false,
      })
    case productActions.PRODUCT_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case 'PRODUCT_FILTER_UPDATE':
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true,
      })

    case productActions.PRODUCT_CREATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errors: {},
      })

    case productActions.PRODUCT_CREATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    case productActions.PRODUCT_CREATE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    case productActions.PRODUCT_RETRIEVE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errors: {},
      })
    case productActions.PRODUCT_RETRIEVE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case productActions.PRODUCT_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case productActions.PRODUCT_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    default:
      return state
  }
}

export function deleteProduct(
  state = {
    isFetching: false,
    data: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case productActions.PRODUCT_DELETE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case productActions.PRODUCT_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    default:
      return state
  }
}

export function activeProduct(
  state = {
    isFetching: false,
    data: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case productActions.PRODUCT_ACTIVE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case productActions.PRODUCT_ACTIVE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    default:
      return state
  }
}

export function importExcelProduct(
  state = {
    isFetching: false,
    data: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case productActions.PRODUCT_EXCEL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    case productActions.PRODUCT_EXCEL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })
    default:
      return state
  }
}
