import {
  LIST_PURSE_REQUEST,
  LIST_PURSE_SUCCESS,
  LIST_PURSE_FAILURE,
  UPDATE_PURSE_FILTERS,
} from 'actions/dashboardActions'

export default function reducer(
  state = {
    isFetching: false,
    data: {},
    errors: {},
    filters: {
      created_at_0: null,
      created_at_1: null,
    },
    needRefresh: false,
  },
  action,
) {
  switch (action.type) {
    case LIST_PURSE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
      })

    case LIST_PURSE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case LIST_PURSE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    case UPDATE_PURSE_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true,
      })

    default:
      return state
  }
}
