import $ from 'jquery'

import { CALL_API } from 'middleware/api'

export const CLIENT_PAYMENTS_REQUEST = 'CLIENT_PAYMENTS_REQUEST'
export const CLIENT_PAYMENTS_FAILURE = 'CLIENT_PAYMENTS_FAILURE'
export const CLIENT_PAYMENTS_SUCCESS = 'CLIENT_PAYMENTS_SUCCESS'

export const CLIENT_PROFILE_REQUEST = 'CLIENT_PROFILE_REQUEST'
export const CLIENT_PROFILE_FAILURE = 'CLIENT_PROFILE_FAILURE'
export const CLIENT_PROFILE_SUCCESS = 'CLIENT_PROFILE_SUCCESS'

export const CLIENT_STATUS_REASON_REQUEST = 'CLIENT_STATUS_REASON_REQUEST'
export const CLIENT_STATUS_REASON_FAILURE = 'CLIENT_STATUS_REASON_FAILURE'
export const CLIENT_STATUS_REASON_SUCCESS = 'CLIENT_STATUS_REASON_SUCCESS'

export const CLIENT_UPDATE_REQUEST = 'CLIENT_UPDATE_REQUEST'
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS'
export const CLIENT_UPDATE_FAILURE = 'CLIENT_UPDATE_FAILURE'

export const UPDATE_PURSE = 'UPDATE_PURSE'
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'

export function listPaymentMethods() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `payment_methods/`,
      authenticated: true,
      types: [
        CLIENT_PAYMENTS_REQUEST,
        CLIENT_PAYMENTS_SUCCESS,
        CLIENT_PAYMENTS_FAILURE,
      ],
    },
  }
}

export function listProfileData() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `profile/`,
      authenticated: true,
      types: [
        CLIENT_PROFILE_REQUEST,
        CLIENT_PROFILE_SUCCESS,
        CLIENT_PROFILE_FAILURE,
      ],
    },
  }
}

export function listStatusReasonData(params = {}) {
  let url = `status_reason/`
  const qs = $.param(params, true)
  if (qs) {
    url += '?' + qs
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: [
        CLIENT_STATUS_REASON_REQUEST,
        CLIENT_STATUS_REASON_SUCCESS,
        CLIENT_STATUS_REASON_FAILURE,
      ],
    },
  }
}

export function updatePurse(purse) {
  return function(dispatch) {
    const action = {
      type: UPDATE_PURSE,
      purse,
    }
    dispatch(action)
  }
}

export function updatePayment(payment) {
  return function(dispatch) {
    const action = {
      type: UPDATE_PAYMENT,
      payment,
    }
    dispatch(action)
  }
}

export function updateProfile(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `profile/`,
      authenticated: true,
      data: data,
      types: [
        CLIENT_UPDATE_REQUEST,
        CLIENT_UPDATE_SUCCESS,
        CLIENT_UPDATE_FAILURE,
      ],
    },
  }
}
