import React from 'react'
import PropTypes from 'prop-types'
import { getToastStyle } from 'utils/css/toastStyles'
import './FancyToast.css'

const FancyToast = ({ type, title, message, onClose }) => {
  const { color, icon } = getToastStyle(type)

  return (
    <div className="fancy-toast" style={{ borderLeft: `6px solid ${color}` }}>
      <div className="fancy-toast-icon">{icon}</div>
      <div className="fancy-toast-content">
        <strong>{title}</strong>
        <p>{message}</p>
      </div>
      <button className="fancy-toast-close" onClick={onClose}>
        ×
      </button>
    </div>
  )
}

FancyToast.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default FancyToast
