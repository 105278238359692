import axios from 'axios'
import moment from 'moment-timezone'

const BASE_URL = process.env.REACT_APP_BASE_API_URL
const BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN
const BASE_LOCAL_URL = 'http://localhost:3004/'
const BASE_URL_STORE = process.env.REACT_APP_STORE_URL //'http://127.0.0.1:8000/api/' //
const BASE_TRACKING_URL = process.env.REACT_APP_TRACKER_URL

let CancelToken
let cancel

const calculateUrl = (isPublic, localAPI, endpoint, storeAPI, isTracking) => {
  let url
  if (localAPI) {
    url = `${BASE_LOCAL_URL}${endpoint}`
  } else if (isPublic) {
    url = `${BASE_DOMAIN}${endpoint}`
  } else if (storeAPI) {
    url = `${BASE_URL_STORE}${endpoint}`
  } else if (isTracking) {
    url = `${BASE_TRACKING_URL}${endpoint}`
  } else {
    url = `${BASE_URL}${endpoint}`
  }
  return url
}

export function callApi(
  method,
  endpoint,
  data,
  authenticated,
  localAPI,
  isPublic,
  msg,
  storeAPI,
  register,
  setToken,
  isTracking,
) {
  if (msg === 'order') {
    CancelToken = axios.CancelToken
  }
  let token = localStorage.getItem('token_web') || null
  let tokenAdmin = localStorage.getItem('token_admin') || null

  let config = {
    method: method,
    url: calculateUrl(isPublic, localAPI, endpoint, storeAPI, isTracking),
    data: data,
    cancelToken: CancelToken
      ? new CancelToken(c => {
          cancel = c
        })
      : false,
    headers: {
      'Accept-Language': 'es',
      'Time-Zone': moment.tz.guess(),
    },
  }
  if (authenticated) {
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `JWT ${token}`,
      }
    } else if (tokenAdmin) {
      config.headers = {
        ...config.headers,
        Authorization: `JWT ${tokenAdmin}`,
      }
    }
  }
  if (register && token) {
    config.headers = {
      ...config.headers,
      Register: `JWT ${token}`,
    }
  }
  if (setToken) {
    config.headers = {
      ...config.headers,
      Authorization: `JWT ${setToken}`,
    }
  }
  return axios(config)
}

export const CALL_API = Symbol('Call API')

export default store => next => action => {
  const callAPI = action[CALL_API]

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  let {
    method,
    endpoint,
    data,
    authenticated,
    types,
    extra,
    localAPI,
    isPublic,
    msg,
    storeAPI,
    register,
    setToken,
    isTracking,
  } = callAPI
  const [requestType, successType, errorType] = types

  if (cancel && msg === 'order') {
    cancel()
  }
  next({ type: requestType, extra: extra })

  return callApi(
    method,
    endpoint,
    data,
    authenticated,
    localAPI,
    isPublic,
    msg,
    storeAPI,
    register,
    setToken,
    isTracking,
  )
    .then(response => {
      return next({
        extra: extra,
        data: response.data,
        type: successType,
      })
    })
    .catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear()
        } else if (error.response.status === 500) {
          return next({
            errors: [
              'Parece que algo salió mal, por favor inténtelo de nuevo más tarde.',
            ],
            type: errorType,
          })
        }
        return next({
          errors: error.response ? error.response.data : { error: 'error' },
          type: errorType,
        })
      }
    })
}
