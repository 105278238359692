import Typography from 'typography'

import 'assets/fonts/fonts.css'

export const fonts = {
  regular: 'ProximaNova Regular',
  light: 'ProximaNova Light',
  thin: 'ProximaNova Thin',
  semibold: 'ProximaNova SemiBold',
}

const typography = new Typography({
  baseFontSize: '16px',
  baseLineHeight: 1.55,
  headerFontFamily: [fonts.semibold, 'sans-serif'],
  bodyFontFamily: [fonts.regular, 'sans-serif'],
  headerColor: '#323e48',
  bodyColor: '#323e48',
  includeNormalize: false,

  overrideStyles: ({ rhythm }) => ({
    'h1,h2,h3,h4,h5,h6': {
      lineHeight: 1,
    },
    'h1,h2,h3,h4': {
      lineHeight: 1.25,
      marginTop: rhythm(1),
      marginBottom: rhythm(1 / 2),
    },
    'h1,h2,h3,h4,h5,h6,strong': {
      fontFamily: `${fonts.regular}, sans-serif`,
      fontWeight: 400,
    },
    small: {
      fontSize: '13px !important',
    },
    b: {
      fontWeight: 'bold !important',
    },
    figure: {
      marginBottom: 0,
    },
  }),
})

typography.injectStyles()

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
