import {
  SHOW_CONFIRMMESSAGE,
  HIDE_CONFIRMMESSAGE,
} from 'actions/all/messageActions'

export default function reducer(
  state = {
    isMessageActive: false,
    type: '',
    extra: '',
  },
  action,
) {
  switch (action.type) {
    case SHOW_CONFIRMMESSAGE:
      return Object.assign({}, state, {
        isMessageActive: true,
        type: action.payload,
        extra: action.extra,
      })
    case HIDE_CONFIRMMESSAGE:
      return Object.assign({}, state, {
        isMessageActive: false,
        type: '',
        extra: '',
      })
    default:
      return state
  }
}
