import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from 'actions/accountActions'
import { EditProfileState, EditProfileActionTypes } from './types'

const initialState: EditProfileState = {
  isFetching: false,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  action: EditProfileActionTypes,
) {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errors: undefined,
      })

    case CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        errors: undefined,
      })

    case CHANGE_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data,
      })

    default:
      return state
  }
}
