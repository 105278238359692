import {
  AVAILABLE_COUNTRIES_REQUEST,
  AVAILABLE_COUNTRIES_SUCCESS,
  AVAILABLE_COUNTRIES_FAILURE,
} from 'actions/all/regionalizationActions'
import { AvailableCountriesState, AvailableCountriesActionTypes } from './types'

const initialState: AvailableCountriesState = {
  isFetching: false,
  countries: undefined,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  action: AvailableCountriesActionTypes,
): AvailableCountriesState {
  switch (action.type) {
    case AVAILABLE_COUNTRIES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case AVAILABLE_COUNTRIES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        countries: action.data.results,
      })
    case AVAILABLE_COUNTRIES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data,
      })

    default:
      return state
  }
}
