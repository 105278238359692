import {
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
} from 'actions/orderActions'
import { CreateOrderActionTypes, CreateOrderState } from './types'

const initialState: CreateOrderState = {
  isFetching: false,
  data: undefined,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  actions: CreateOrderActionTypes,
): CreateOrderState {
  switch (actions.type) {
    case CREATE_ORDER_REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: actions.data,
      }

    case CREATE_ORDER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: actions.data,
      }

    default:
      return state
  }
}
