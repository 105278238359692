import $ from 'jquery'

import { CALL_API } from 'middleware/api'
export const EDIT_FAVORITE = 'EDIT_FAVORITE'
export const CLEAR_FAVORITE = 'CLEAR_FAVORITE'
export const SEND_FAVORITE = 'SEND_FAVORITE'

export const CLEANING_DONE = 'CLEANING_DONE'
export const UPDATE_ICONHEART = 'UPDATE_ICONHEART'
export const CLEAR_USE_DEFAULT_ADDRESS = 'CLEAR_USE_DEFAULT_ADDRESS'
let favoriteActions = {}

const genTypeActions = (entity, action) => {
  let arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  let arrType = arr.map(el => {
    let type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    favoriteActions[type] = type
    return type
  })
  return arrType
}

export function getListFavorites(params = {}) {
  let url = `favorite_address/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: genTypeActions('favorites', 'list'),
    },
  }
}

export function saveFavorites(data, extra) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `favorite_address/`,
      authenticated: true,
      data: data,
      extra,
      types: genTypeActions('get', 'favorite'),
    },
  }
}

export function editFavorite(data) {
  return function(dispatch) {
    const action = {
      type: EDIT_FAVORITE,
      favorite: data,
    }
    dispatch(action)
  }
}

export function sendFavorite(data) {
  return function(dispatch) {
    const action = {
      type: SEND_FAVORITE,
      data,
    }
    dispatch(action)
  }
}

export function clearFavorite() {
  return function(dispatch) {
    const action = {
      type: CLEAR_FAVORITE,
    }
    dispatch(action)
  }
}

export function cleaningDone(params) {
  return function(dispatch) {
    const action = {
      type: CLEANING_DONE,
      params,
    }
    dispatch(action)
  }
}

export function clearUseDefault() {
  return function(dispatch) {
    const action = {
      type: CLEAR_USE_DEFAULT_ADDRESS,
    }
    dispatch(action)
  }
}

export function updateIconHeart(data) {
  return function(dispatch) {
    const action = {
      type: UPDATE_ICONHEART,
      data,
    }
    dispatch(action)
  }
}

export function deleteFavorite(id, extra) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `favorite_address/${id}/`,
      authenticated: true,
      extra,
      types: genTypeActions('delete', 'favorite'),
    },
  }
}

export function updateFavorite(data) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `favorite_address/${data.id}/`,
      authenticated: true,
      data,
      types: genTypeActions('update', 'favorite'),
    },
  }
}

export function getAllFavorites(params = {}) {
  let url = `complete/favorites/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: genTypeActions('favorites', 'getall'),
    },
  }
}

export function getLastAddress(params = {}) {
  let url = `complete/destinations/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: genTypeActions('favorites', 'lastaddress'),
    },
  }
}

genTypeActions('get', 'favorite')
genTypeActions('favorites', 'lastaddress')
genTypeActions('favorites', 'getall')
genTypeActions('list', 'favorite')
genTypeActions('delete', 'favorite')
genTypeActions('update', 'favorite')
export default favoriteActions
