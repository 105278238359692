import { CALL_API } from 'middleware/api'

export const GET_VEHICLE_CATEGORIES_REQUEST = 'GET_VEHICLE_CATEGORIES_REQUEST'
export const GET_VEHICLE_CATEGORIES_SUCCESS = 'GET_VEHICLE_CATEGORIES_SUCCESS'
export const GET_VEHICLE_CATEGORIES_FAILURE = 'GET_VEHICLE_CATEGORIES_FAILURE'

interface Destinations {
  destinations: { latlon: string }[]
}

export function getVehicleCategories(data: Destinations) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'vehicle_categories/',
      authenticated: true,
      data,
      types: [
        GET_VEHICLE_CATEGORIES_REQUEST,
        GET_VEHICLE_CATEGORIES_SUCCESS,
        GET_VEHICLE_CATEGORIES_FAILURE,
      ],
    },
  }
}
