import { CALL_API } from 'middleware/api'
export const EDIT_FAVORITE = 'EDIT_FAVORITE'
export const OPEN_MODAL_RATE = 'OPEN_MODAL_RATE'

let raitingActions = {}

const genTypeActions = (entity, action) => {
  let arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  let arrType = arr.map(el => {
    let type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    raitingActions[type] = type
    return type
  })
  return arrType
}

export function openModalRate(score, id) {
  return function(dispatch) {
    const action = {
      type: OPEN_MODAL_RATE,
      data: { score, id },
    }
    dispatch(action)
  }
}

export function updateRate(data) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `orders/${data.id}/rate/`,
      authenticated: true,
      data,
      types: genTypeActions('update', 'raiting'),
    },
  }
}

genTypeActions('update', 'raiting')
export default raitingActions
