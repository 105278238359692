import $ from 'jquery'

import { CALL_API } from 'middleware/api'
export const EDIT_GUEST = 'EDIT_GUEST'
export const CLEAR_GUEST = 'CLEAR_GUEST'
export const SEND_GUEST = 'SEND_GUEST'
export const CLEAN_GUEST = 'CLEAN_GUEST'
export const OPEN_GUEST = 'OPEN_GUEST'
export const UPDATE_GUESTS_FILTERS = 'UPDATE_GUESTS_FILTERS'
export const CLEAN_ERRORS = 'CLEAN_ERRORS'

let guestActions = {}

const genTypeActions = (entity, action) => {
  let arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  let arrType = arr.map(el => {
    let type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    guestActions[type] = type
    return type
  })
  return arrType
}

export function getListGuests(params = {}) {
  let url = `guests/`
  let qs = $.param(params, true)
  if (qs) url += '?' + qs
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: genTypeActions('guest', 'list'),
    },
  }
}

export function saveGuests(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `guests/`,
      authenticated: true,
      data: data,
      types: genTypeActions('guest', 'save'),
    },
  }
}

export function editGuest(data) {
  return function(dispatch) {
    const action = {
      type: EDIT_GUEST,
      guest: data,
    }
    dispatch(action)
  }
}
export function openFormGuest(data) {
  return function(dispatch) {
    const action = {
      type: OPEN_GUEST,
      open: data,
    }
    dispatch(action)
  }
}

export function cleanGuest() {
  return function(dispatch) {
    const action = {
      type: CLEAN_GUEST,
    }
    dispatch(action)
  }
}

/*
For the <deleteguest> function, we leave the id value in props, since when it is modified 
in its construction to 'POST' and in 'types' to another value according to its form, this 
rendering stops when trying to refresh the page. To do this in 'types' we keep it with the 
'delete' context.
*/

export function deleteguest(id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `guests/${id}/toggle_activate/`,
      authenticated: true,
      types: genTypeActions('guest', 'delete'),
    },
  }
}

export function updateguest(data) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `guests/${data.id}/`,
      authenticated: true,
      data,
      types: genTypeActions('guest', 'update'),
    },
  }
}

export function massiveGuest(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `guests/create_massive/`,
      authenticated: true,
      data: data,
      types: genTypeActions('guest', 'massive'),
    },
  }
}

export function updateGuestsFilters(data) {
  return function(dispatch) {
    const action = {
      type: UPDATE_GUESTS_FILTERS,
      data: data,
    }
    dispatch(action)
  }
}

export function cleanErrors() {
  return function(dispatch) {
    const action = {
      type: CLEAN_ERRORS,
    }
    dispatch(action)
  }
}

genTypeActions('guest', 'list')
genTypeActions('guest', 'delete')
genTypeActions('guest', 'update')
genTypeActions('guest', 'massive')
export default guestActions
