import { CALL_API } from 'middleware/api'
import { LoginCredentials } from 'reducers/login/types'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const CLIENT_TYPE_REQUEST = 'CLIENT_TYPES_REQUEST'
export const CLIENT_TYPE_SUCCESS = 'CLIENT_TYPES_SUCCESS'
export const CLIENT_TYPE_FAILURE = 'CLIENT_TYPES_FAILURE'

export function loginUser(creds: LoginCredentials) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'authenticate/jwt/',
      authenticated: false,
      data: creds,
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    },
  }
}

export function clientTypes() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'api/client_type/',
      authenticated: false,
      isPublic: true,
      types: [CLIENT_TYPE_REQUEST, CLIENT_TYPE_SUCCESS, CLIENT_TYPE_FAILURE],
    },
  }
}
