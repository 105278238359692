import favoriteActions, {
  SEND_FAVORITE,
  EDIT_FAVORITE,
  CLEAR_FAVORITE,
  CLEANING_DONE,
  CLEAR_USE_DEFAULT_ADDRESS,
  UPDATE_ICONHEART,
} from 'actions/favoriteActions'
import _ from 'lodash'

export default function reducer(
  state = {
    isFetching: false,
    data: {},
    profile: false,
    filters: {
      page: 1,
      ordering: 'created_at',
    },
    editFavorite: false,
    errors: {},
    favoriteAddress: false,
    cleaningCreate: false,
    clickUseAddress: false,
    iconHeart: [],
    updateHeart: false,
    suggestions: [],
  },
  action,
) {
  switch (action.type) {
    case favoriteActions.FAVORITES_LIST_REQUEST:
      localStorage.removeItem('favoriteAddress')
      return Object.assign({}, state, {
        isFetching: true,
        favoriteAddress: false,
      })
    case favoriteActions.FAVORITES_LIST_SUCCESS:
      let hasAddress = localStorage.getItem('favoriteAddress')
      if (!hasAddress) {
        localStorage.setItem(
          'favoriteAddress',
          JSON.stringify(action.data.results[0]),
        )
      }
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        favoriteAddress: action.data.results[0],
        filters: { page: action.data.current_page },
      })
    case CLEAR_USE_DEFAULT_ADDRESS:
      return Object.assign({}, state, { favoriteAddress: false })
    case CLEAR_FAVORITE:
      return Object.assign({}, state, {
        editFavorite: false,
        cleaningCreate: true,
      })
    case SEND_FAVORITE:
      return Object.assign({}, state, {
        favoriteAddress: action.data,
        clickUseAddress: true,
      })
    case CLEANING_DONE:
      return Object.assign({}, state, { cleaningCreate: false })
    case favoriteActions.UPDATE_FAVORITE_SUCCESS:
    case favoriteActions.DELETE_FAVORITE_SUCCESS:
      let cloneIcon = []
      let cloneSuggestionsDelete = []
      if (window.location.pathname === '/mi-cuenta/favoritos') {
        window.location = '/mi-cuenta/favoritos'
      } else {
        cloneIcon = _.clone(state.iconHeart)
        cloneSuggestionsDelete = _.clone(state.suggestions)
        cloneSuggestionsDelete.splice(action.extra, 1)
        cloneIcon[action.extra] = {
          isSelected: false,
          id: cloneIcon[action.extra].id,
        }
      }
      return Object.assign({}, state, {
        editFavorite: false,
        iconHeart: cloneIcon,
        suggestions: cloneSuggestionsDelete,
      })
    case EDIT_FAVORITE:
      let tmp = _.clone(action.favorite)
      let objLatlon = { coordinates: tmp.latlon, name: tmp.address }
      let newObj = Object.assign({}, action.favorite, {
        latlon: objLatlon,
      })
      return Object.assign({}, state, { editFavorite: newObj })
    case favoriteActions.FAVORITES_GETALL_REQUEST:
      return Object.assign({}, state, { suggestions: [] })
    case favoriteActions.FAVORITES_GETALL_SUCCESS:
      let suggestions = _.uniqBy(action.data, 'latlon')
      return Object.assign({}, state, { suggestions: suggestions })
    case favoriteActions.FAVORITES_GETALL_FAILURE:
      return Object.assign({}, state, { errors: action })
    case favoriteActions.GET_FAVORITE_SUCCESS:
      let clone = _.clone(state.iconHeart)
      let cloneSuggestions = _.clone(state.suggestions)
      cloneSuggestions.push(action.data)
      clone[action.extra] = { isSelected: true, id: action.data.id }
      return Object.assign({}, state, {
        iconHeart: clone,
        suggestions: cloneSuggestions,
      })
    case UPDATE_ICONHEART:
      return Object.assign({}, state, {
        iconHeart: action.data,
        updateHeart: true,
      })
    default:
      return state
  }
}
