export const SELECT_ROW = 'SELECT_ROW';
export const SELECT_ALL_ROWS = 'SELECT_ALL_ROWS';
export const CLEAN_SELECTED_ROWS = 'CLEAN_SELECTED_ROWS';

export function selectRow(selected) {
  return dispatch => {
    const action = {
      type: SELECT_ROW,
      selected
    };
    dispatch(action);
  };
}

export function selectAllRows(selected) {
  return dispatch => {
    const action = {
      type: SELECT_ALL_ROWS,
      selected
    };
    dispatch(action);
  };
}

export function cleanSelectedRows() {
  return dispatch => {
    const action = {
      type: CLEAN_SELECTED_ROWS
    };
    dispatch(action);
  };
}
