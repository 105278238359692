import {
  CLIENT_PROFILE_REQUEST,
  CLIENT_PROFILE_SUCCESS,
  CLIENT_PROFILE_FAILURE,
  UPDATE_PURSE,
  UPDATE_PAYMENT,
} from 'actions/clientActions'
import { ClientDataActionTypes, ClientDataState } from './types'

const initialState: ClientDataState = {
  isFetching: false,
  profile: undefined,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  action: ClientDataActionTypes,
): ClientDataState {
  switch (action.type) {
    case CLIENT_PROFILE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })

    case CLIENT_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        profile: action.data,
      })

    case CLIENT_PROFILE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })
    case UPDATE_PURSE:
      return Object.assign({}, state, {
        profile: Object.assign({}, state.profile, {
          purse: action.purse,
          isPagoEfectivo: true,
        }),
      })

    case UPDATE_PAYMENT:
      return Object.assign({}, state, {
        profile: Object.assign({}, state.profile, {
          has_payment_method: action.payment,
        }),
      })

    default:
      return state
  }
}
