import {
  GET_FAVORITE_ADDRESSES_REQUEST,
  GET_FAVORITE_ADDRESSES_SUCCESS,
  GET_FAVORITE_ADDRESSES_FAILURE,
} from 'actions/favoritAddressActions'
import { FavoriteAddressesActions, FavoriteAddressesState } from './types'

const initialState: FavoriteAddressesState = {
  isFetching: false,
  data: undefined,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  actions: FavoriteAddressesActions,
): FavoriteAddressesState {
  switch (actions.type) {
    case GET_FAVORITE_ADDRESSES_REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case GET_FAVORITE_ADDRESSES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: actions.data,
      }

    case GET_FAVORITE_ADDRESSES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: actions.data,
      }

    default:
      return state
  }
}
