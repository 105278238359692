import { WS_MESSAGE } from 'actions/wsActions'
import trackingActions from 'actions/TrackingActions'

let parseDestinations = des => {
  let newDes = des.map(el => {
    let obj = { latlon: {} }
    obj.latlon.coordinates = el.latlon
    let combineObjects = Object.assign({}, el, obj)
    return combineObjects
  })
  return newDes
}

export default function reducer(
  state = {
    data: {},
    extraData: {},
    errors: {},
    courier_default: false,
    courierData: false,
    needRefresh: false,
    isFetching: false,
    new_tracking_service: false,
  },
  action,
) {
  switch (action.type) {
    case trackingActions.TRACKING_GETDATA_REQUEST:
      return Object.assign({}, state, {
        needRefresh: false,
      })
    case trackingActions.TRACKING_GETDATA_SUCCESS:
      let courierData = {}
      if (action.data.courier_latitude) {
        courierData = {
          position: `${action.data.courier_latitude},${
            action.data.courier_longitude
          }`,
          vehicle: action.data.vehicle,
          lat: action.data.courier_latitude,
          lng: action.data.courier_longitude,
        }
      } else {
        courierData = { vehicle: action.data.vehicle }
      }
      return Object.assign({}, state, {
        data: action.data,
        destinations: parseDestinations(action.data.destinations),
        courierData,
      })

    case WS_MESSAGE:
      if (action.data.event === 'courier:update_position') {
        return Object.assign({}, state, {
          courierData: Object.assign({}, state.courierData, {
            bearing: action.data.data.bearing,
            lat: action.data.data.latitude,
            lng: action.data.data.longitude,
          }),
        })
      }
      let wsMessage = {
        needRefresh: false,
      }
      if (
        (action.data.stream === 'order' ||
          action.data.stream === 'destination') &&
        action.entity === 'tracking'
      ) {
        wsMessage = {
          needRefresh: true,
        }
      }
      return Object.assign({}, state, wsMessage)

    default:
      return state
  }
}
