import {
  ORDER_PRICE_REQUEST,
  ORDER_PRICE_SUCCESS,
  ORDER_PRICE_FAILURE,
  ORDER_DELIVERY_WINDOWS_REQUEST,
  ORDER_DELIVERY_WINDOWS_SUCCESS,
  ORDER_DELIVERY_WINDOWS_FAILURE,
  NEW_SAVE_DRAFT_REQUEST,
  NEW_SAVE_DRAFT_SUCCESS,
  NEW_SAVE_DRAFT_FAILURE,
  OPTIMIZE_DESTINATIONS_REQUEST,
  OPTIMIZE_DESTINATIONS_SUCCESS,
  OPTIMIZE_DESTINATIONS_FAILURE,
} from 'actions/orderActions'
import { OrderPriceActionTypes, OrderPriceState } from './types'

const initialState: OrderPriceState = {
  isFetching: false,
  isSavingDraft: false,
  draftSaved: false,
  optimizingDestinations: false,
  data: undefined,
  deliveryWindows: undefined,
  optimizedDestinations: undefined,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  actions: OrderPriceActionTypes,
): OrderPriceState {
  switch (actions.type) {
    case ORDER_PRICE_REQUEST:
      return {
        ...state,
        data: undefined,
        isFetching: true,
      }

    case ORDER_PRICE_SUCCESS:
      return {
        ...state,
        data: actions.data,
        isFetching: false,
      }

    case ORDER_PRICE_FAILURE:
      return {
        ...state,
        errors: actions.errors,
        isFetching: false,
        data: undefined,
      }

    case ORDER_DELIVERY_WINDOWS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case ORDER_DELIVERY_WINDOWS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        deliveryWindows: actions.data.results,
      }

    case ORDER_DELIVERY_WINDOWS_FAILURE:
      return {
        ...state,
        isFetching: false,
      }

    case NEW_SAVE_DRAFT_REQUEST:
      return {
        ...state,
        isSavingDraft: true,
        draftSaved: false,
      }

    case NEW_SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        isSavingDraft: false,
        draftSaved: true,
      }

    case NEW_SAVE_DRAFT_FAILURE:
      return {
        ...state,
        isSavingDraft: false,
        draftSaved: false,
      }

    case OPTIMIZE_DESTINATIONS_REQUEST:
      return {
        ...state,
        optimizingDestinations: true,
      }

    case OPTIMIZE_DESTINATIONS_SUCCESS:
      return {
        ...state,
        optimizingDestinations: false,
        optimizedDestinations: actions.data,
      }

    case OPTIMIZE_DESTINATIONS_FAILURE:
      return {
        ...state,
        optimizingDestinations: false,
      }

    default:
      return state
  }
}
