import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
} from 'actions/userActions'
import mixpanel from 'utils/js/mixpanel'
import { LoginState, LoginActionTypes } from './types'

const initialState: LoginState = {
  isAuthenticated: Boolean(localStorage.getItem('token_web')),
  isFetching: false,
  data: undefined,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  action: LoginActionTypes,
): LoginState {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        creds: action.creds,
      })

    case LOGIN_SUCCESS:
      localStorage.setItem('token_web', action.data.auth_token)
      mixpanel.loginSuccess(action.data.client.user_id)
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        data: action.data.client,
      })

    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errors: action.errors,
      })

    default:
      return state
  }
}
