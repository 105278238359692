import {
  CREDIT_SUCCESS,
  LIST_CARDS_REQUEST,
  LIST_CARDS_SUCCESS,
  LIST_CARDS_FAILURE,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAILURE,
} from 'actions/accountActions'
import { PaymentsState, PaymentsActionTypes } from './types'

const initialState: PaymentsState = {
  isFetching: false,
  isDeleting: false,
  data: undefined,
  newCard: undefined,
  errors: undefined,
  err: false,
  errorsDelete: undefined,
  update: false,
}

export default function reducer(
  state = initialState,
  action: PaymentsActionTypes,
) {
  switch (action.type) {
    case CREDIT_SUCCESS:
      if (state.data) {
        return Object.assign({}, state, {
          isFetching: false,
          data: [action.newCard, ...state.data],
        })
      }
      return Object.assign({}, state, {
        isFetching: false,
        data: [action.newCard],
      })

    case LIST_CARDS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errors: {},
        errorsDelete: {},
      })

    case LIST_CARDS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data.results,
      })

    case LIST_CARDS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: undefined,
      })

    case DELETE_CARD_REQUEST:
      return Object.assign({}, state, {
        isDeleting: true,
        errorsDelete: undefined,
      })

    case DELETE_CARD_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        data: state.data
          ? {
              ...state.data,
              results: state.data.filter(card => card.id !== action.extra),
            }
          : undefined,
        update: true,
      })

    case DELETE_CARD_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        errorsDelete: action.errors || 'Error al eliminar la tarjeta',
        err: true,
      })

    default:
      return state
  }
}
