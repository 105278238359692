import {
  GET_VEHICLE_CATEGORIES_REQUEST,
  GET_VEHICLE_CATEGORIES_SUCCESS,
  GET_VEHICLE_CATEGORIES_FAILURE,
} from 'actions/vehicleCategoriesActions'
import { VehicleCategoriesActionTypes, VehicleCategoriesState } from './types'

const initialState: VehicleCategoriesState = {
  isFetching: false,
}

export default function reducer(
  state = initialState,
  actions: VehicleCategoriesActionTypes,
): VehicleCategoriesState {
  switch (actions.type) {
    case GET_VEHICLE_CATEGORIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case GET_VEHICLE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: actions.data.results,
      }

    case GET_VEHICLE_CATEGORIES_FAILURE:
      return {
        ...state,
        categories: undefined,
        errors: actions.data,
      }

    default:
      return state
  }
}
