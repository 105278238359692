import {
  ORDER_TYPES_REQUEST,
  ORDER_TYPES_SUCCESS,
  ORDER_TYPES_FAILURE,
} from 'actions/orderActions'
import { OrderTypesState, OrderTypesActionTypes } from './types'

const initialState: OrderTypesState = {
  isFetching: false,
  data: undefined,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  action: OrderTypesActionTypes,
) {
  switch (action.type) {
    case ORDER_TYPES_REQUEST:
      return { ...state, isFetching: true }

    case ORDER_TYPES_SUCCESS:
      return { ...state, isFetching: false, data: action.data.results }

    case ORDER_TYPES_FAILURE:
      return { ...state, isFetching: false, errors: action.data }

    default:
      return state
  }
}
