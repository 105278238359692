import orderActions from 'actions/merchantActions'
import { WS_MESSAGE } from 'actions/wsStore'

export function listOrder(
  state = {
    isFetching: false,
    reloadDetail: false,
    needRefresh: false,
    data: false,
    errors: {},
    filters: {
      page: 1,
      code: '',
    },
  },
  action,
) {
  switch (action.type) {
    case orderActions.ORDER_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        reloadDetail: false,
        needRefresh: false,
      })
    case orderActions.ORDER_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case WS_MESSAGE:
      let wsMessage = {}
      if (
        action.entity === 'ORDER_LIST_STORE' &&
        action.data.stream === 'order'
      ) {
        wsMessage = {
          reloadDetail: true,
          stream: action.data,
        }
      }
      return Object.assign({}, state, wsMessage)

    case 'ORDER_FILTER_UPDATE':
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true,
      })

    default:
      return state
  }
}

export function OrderWs(
  state = {
    reloadDetail: false,
    data: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case WS_MESSAGE:
      let wsMessage = {}
      if (
        action.entity === 'ORDER_LIST_STORE' &&
        action.data.stream === 'order'
      ) {
        wsMessage = {
          reloadDetail: true,
          stream: action.data,
        }
      }
      return Object.assign({}, state, wsMessage)

    default:
      return state
  }
}

export function listOrderHistoric(
  state = {
    isFetching: false,
    reloadDetail: false,
    needRefresh: false,
    data: false,
    errors: {},
    filters: {
      page: 1,
      code: '',
    },
  },
  action,
) {
  switch (action.type) {
    case orderActions.ORDER_HISTORIC_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        reloadDetail: false,
        needRefresh: false,
      })
    case orderActions.ORDER_HISTORIC_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    case 'HISTORY_FILTER_UPDATE':
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true,
      })

    default:
      return state
  }
}

export function listIncomes(
  state = {
    isFetching: false,
    data: false,
    errors: {},
    filters: {
      page: 1,
      code: '',
    },
  },
  action,
) {
  switch (action.type) {
    case orderActions.INCOMES_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case orderActions.INCOMES_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    default:
      return state
  }
}

export function totalIncomes(
  state = {
    isFetching: false,
    data: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case orderActions.INCOMES_TOTAL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case orderActions.INCOMES_TOTAL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    default:
      return state
  }
}

export function retrieveOrder(
  state = {
    isFetching: false,
    data: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case orderActions.ORDER_RETRIEVE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case orderActions.ORDER_RETRIEVE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    default:
      return state
  }
}

export function pendingOrder(
  state = {
    isFetching: false,
    data: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case orderActions.ORDER_PENDING_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case orderActions.ORDER_PENDING_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    default:
      return state
  }
}

export function retrieveOrderDelivery(
  state = {
    isFetching: false,
    data: false,
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case orderActions.DELIVERY_RETRIEVE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case orderActions.DELIVERY_RETRIEVE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    default:
      return state
  }
}
