import {
  CHECK_DANGER_COVERAGE_REQUEST,
  CHECK_DANGER_COVERAGE_SUCCESS,
  CHECK_DANGER_COVERAGE_FAILURE,
} from '../../actions/orderActions'

export default function reducer(
  state = {
    isFetching: false,
    data: [],
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case CHECK_DANGER_COVERAGE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        data: [],
        errors: {},
      })

    case CHECK_DANGER_COVERAGE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case CHECK_DANGER_COVERAGE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    default:
      return state
  }
}
