import promotionActions from 'actions/productActions'

export default function reducer(
  state = {
    isFetching: false,
    data: false,
    profile: {},
    filters: {
      page: 1,
    },
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case promotionActions.PROMOTION_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case promotionActions.PROMOTION_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        filters: { page: 1 },
      })

    case promotionActions.PROMOTION_CREATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errors: {},
      })
    case promotionActions.PROMOTION_CREATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })
    case promotionActions.PROMOTION_CREATE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    case promotionActions.PROMOTION_RETRIEVE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errors: {},
      })
    case promotionActions.PROMOTION_RETRIEVE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case promotionActions.PROMOTION_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })

    case promotionActions.PROMOTION_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        filters: { page: 1 },
      })

    default:
      return state
  }
}
