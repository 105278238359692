import {
  ORDERS_HISTORICAL_REQUEST,
  ORDERS_HISTORICAL_SUCCESS,
  ORDERS_HISTORICAL_FAILURE,
  UPDATE_ORDER_HISTORICAL_FILTERS,
  DUPLICATE_ORDER_REQUEST,
  DUPLICATE_ORDER_SUCCESS,
  DUPLICATE_ORDER_FAILURE,
} from 'actions/dashboardActions'
import ratingActions from 'actions/raitingActions'
import { WS_MESSAGE } from 'actions/wsActions'

export default function reducer(
  state = {
    isFetching: false,
    data: {},
    errors: {},
    wsMessage: null,
    filters: {
      created_at_0: null,
      created_at_1: null,
      search: '',
    },
    needRefresh: false,
  },
  action,
) {
  switch (action.type) {
    case WS_MESSAGE:
      if (
        action.entity === 'REPORT_NOTIFICATION' &&
        action.data.stream === 'report'
      ) {
        return {
          ...state,
          wsMessage: action.data,
        }
      }
      return state

    case ORDERS_HISTORICAL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        errors: {},
      })

    case ORDERS_HISTORICAL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case ORDERS_HISTORICAL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })
    case UPDATE_ORDER_HISTORICAL_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true,
      })
    case ratingActions.UPDATE_RAITING_SUCCESS:
      return Object.assign({}, state, { needRefresh: true })

    case DUPLICATE_ORDER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        errors: {},
      })

    case DUPLICATE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
      })

    case DUPLICATE_ORDER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    default:
      return state
  }
}
