import { SHOW_MODAL_INFO, CLOSE_MODAL_INFO } from 'actions/dashboardActions'

export default function reducer(
  state = {
    modalContent: {},
    show: false,
  },
  action,
) {
  switch (action.type) {
    case SHOW_MODAL_INFO:
      return Object.assign({}, state, {
        modalContent: action.body,
        show: true,
      })

    case CLOSE_MODAL_INFO:
      return Object.assign({}, state, {
        modalContent: {},
        show: false,
      })

    default:
      return state
  }
}
