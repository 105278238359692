/*  Variables for style components */
export interface UrbanerColors {
  green: string
  orange: string
  light_green: string
  dark_gray: string
  sky_blue: string
  gray: string
  middle_gray: string
  medium_gray: string
  light_gray: string
  error_color: string
  dark_green: string
  dark_sky_blue: string
  danger: string
  dark_danger: string
  lima_gray: string
  purse_gray: string
  border: string
}

export const colors: UrbanerColors = {
  //primary colors
  green: '#50e09e',
  light_green: 'rgba(80, 224, 158, 0.2)',
  dark_gray: '#394049',
  orange: '#E85A0B',
  //secondary colors
  sky_blue: '#635dc2',
  //gray
  gray: '#56606D',
  middle_gray: '#9A9FA6',
  medium_gray: '#C9CCD5',
  light_gray: '#f0f0f0',
  //error
  error_color: '#e0245e',
  //hover
  dark_green: '#2CAB75',
  dark_sky_blue: '#4483CC',
  //danger
  danger: '#FF5E70',
  dark_danger: '#CF4B5A',
  //background
  lima_gray: '#f9f9fb',
  //purse
  purse_gray: '#3D5469',
  border: '#e6ebf1',
}

export const breakpoints = {
  mobile: '320px',
  mobile_cutoff: '480px',
  tablet: '780px',
  tablet_cutoff: '840px',
  desktop: '1024px',
}

export const fonts = {
  font_color: '#323e48',
  font_color_secondary: '#50e09e',
  base_font: '16px',
  font_family: 'ProximaNova, Lato, arial, sans-serif',
}

export const extra = {
  container_width: '1140px',
  border_radius: '5px',
  input_height: '2.5rem',
  transition: '0.3s all ease-in',
}
