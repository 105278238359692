export const SEND_DATA = "SEND_DATA";

export function sendData(msg, extra) {
  return function(dispatch) {
    const action = {
      type: SEND_DATA,
      payload: msg,
      extra: extra
    }
    dispatch(action)
  }
}