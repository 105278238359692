import {
  LIST_COUPON_REQUEST,
  LIST_COUPON_SUCCESS,
  LIST_COUPON_FAILURE,
} from 'actions/dashboardActions'

export default function reducer(
  state = {
    isFetching: false,
    data: {},
    errors: {},
  },
  action,
) {
  switch (action.type) {
    case LIST_COUPON_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })

    case LIST_COUPON_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      })

    case LIST_COUPON_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors,
      })

    default:
      return state
  }
}
