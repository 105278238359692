import $ from 'jquery'

import { CALL_API } from 'middleware/api'

export const GET_FAVORITE_ADDRESSES_REQUEST = 'GET_FAVORITE_ADDRESSES_REQUEST'
export const GET_FAVORITE_ADDRESSES_SUCCESS = 'GET_FAVORITE_ADDRESSES_SUCCESS'
export const GET_FAVORITE_ADDRESSES_FAILURE = 'GET_FAVORITE_ADDRESSES_FAILURE'

export const SAVE_FAVORITE_ADDRESS_REQUEST = 'SAVE_FAVORITE_ADDRESS_REQUEST'
export const SAVE_FAVORITE_ADDRESS_SUCCESS = 'SAVE_FAVORITE_ADDRESS_SUCCESS'
export const SAVE_FAVORITE_ADDRESS_FAILURE = 'SAVE_FAVORITE_ADDRESS_FAILURE'

export function getFavoriteAddresses(params = {}) {
  let url = `favorite_address/`
  const qs = $.param(params, true)
  if (qs) {
    url += '?' + qs
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      extra: params,
      authenticated: true,
      types: [
        GET_FAVORITE_ADDRESSES_REQUEST,
        GET_FAVORITE_ADDRESSES_SUCCESS,
        GET_FAVORITE_ADDRESSES_FAILURE,
      ],
    },
  }
}

export function saveFavoriteAddress(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'favorite_address/',
      data,
      authenticated: true,
      types: [
        SAVE_FAVORITE_ADDRESS_REQUEST,
        SAVE_FAVORITE_ADDRESS_SUCCESS,
        SAVE_FAVORITE_ADDRESS_FAILURE,
      ],
    },
  }
}
