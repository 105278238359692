/* eslint-disable no-undef */
import 'normalize.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.css'
import 'components/00-helpers/css/utils.css'
import 'components/00-helpers/css/custom.css'
import 'components/00-helpers/css/merchant.css'
import 'components/00-helpers/css/landing.css'

import React from 'react'
import ReactDOM from 'react-dom'
import Raven from 'raven-js'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

import store from './store'
import GlobalStyle from './GlobalStyle'
import { App } from './App'
import CrispChat from 'utils/js/crisp-chat'

if (
  process.env.REACT_APP_URBANER_ENV === 'production' &&
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_RAVENJS_URL
) {
  Raven.config(process.env.REACT_APP_RAVENJS_URL).install()
  ReactGA.initialize('UA-78719144-1') //  UA - 62286627 - 1;
  ReactGA.pageview(window.location.pathname + window.location.search)
}

ReactPixel.init('647345685742063')
ReactPixel.pageView()

ReactDOM.render(
  <>
    <GlobalStyle />
    <Provider store={store}>
      <App />
    </Provider>
  </>,
  document.getElementById('root'),
)

if (
  window.location.hostname !== 'appsodimac.urbaner.com' &&
  window.location.hostname !== 'cencosud.urbaner.com' &&
  window.location.pathname !== '/tracking/' &&
  window.location.pathname !== '/politicas-tratamiento-de-datos'
)
  CrispChat.integrateChat()
