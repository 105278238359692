class CrispChat {
  setUserToken(token) {
    window.CRISP_TOKEN_ID = token
  }

  setUserName(name) {
    window.$crisp.push(['set', 'user:nickname', [name]])
  }

  setUserEmail(email) {
    window.$crisp.push(['set', 'user:email', email])
  }

  setUserPhone(phone) {
    window.$crisp.push(['set', 'user:phone', phone])
  }

  setUserId(id) {
    window.$crisp.push(['set', 'session:data', ['user_id', id]])
  }

  setPurseBalance(balance) {
    window.$crisp.push(['set', 'session:data', ['purse_balance', balance]])
  }

  setUserIsOwner(isOwner) {
    window.$crisp.push(['set', 'session:data', ['is_owner', isOwner]])
  }

  setUserIsMember(isMember) {
    window.$crisp.push(['set', 'session:data', ['is_member', isMember]])
  }

  setUserHasMembers(hasMembers) {
    window.$crisp.push(['set', 'session:data', ['has_members', hasMembers]])
  }

  setUserType(type) {
    window.$crisp.push(['set', 'session:data', ['user_type', type]])
  }

  setUserOrigin(origin) {
    window.$crisp.push(['set', 'session:data', ['user_origin', origin]])
  }

  setUserOriginCorp(corpName) {
    window.$crisp.push(['set', 'session:data', ['user_origin_corp', corpName]])
  }

  setUserCity(city) {
    window.$crisp.push(['set', 'session:data', ['city', city]])
  }

  setLastOrderDate(date) {
    window.$crisp.push(['set', 'session:data', ['last_order_date', date]])
  }

  setSegment(type, type_origin) {
    window.$crisp.push(['set', 'session:segments', [[type, type_origin]]])
  }

  integrateChat() {
    if (this.canUseChat()) {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_ID
      const script = document.createElement('script')
      script.src = 'https://client.crisp.chat/l.js'
      script.async = true
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }

  canUseChat() {
    if (
      window.location.hostname !== 'appsodimac.urbaner.com' &&
      window.location.hostname !== 'cencosud.urbaner.com'
    )
      return !!process.env.REACT_APP_CRISP_ID
    return false
  }

  logout() {
    window.CRISP_TOKEN_ID = undefined
    window.$crisp.push(['do', 'session:reset', [true]])
  }
}

export default new CrispChat()
