import {
  GET_COURIER_NEARBY_REQUEST,
  GET_COURIER_NEARBY_SUCCESS,
  GET_COURIER_NEARBY_FAILURE,
} from 'actions/nearbyCourierActions'
import { CourierNearbyActionTypes, NearbyCouriersState } from './types'

const initialState: NearbyCouriersState = {
  isFetching: false,
  couriers: undefined,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  action: CourierNearbyActionTypes,
) {
  switch (action.type) {
    case GET_COURIER_NEARBY_REQUEST:
      return {
        ...state,
        isFetching: true,
        couriers: undefined,
      }

    case GET_COURIER_NEARBY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        couriers: action.data,
      }

    case GET_COURIER_NEARBY_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.errors,
      }

    default:
      return state
  }
}
