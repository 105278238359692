import {
  SELECT_ROW,
  SELECT_ALL_ROWS,
  CLEAN_SELECTED_ROWS,
} from 'actions/all/tableSelectActions'

const initialState = {
  selected: [],
  selectedAll: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_ROW:
      return Object.assign({}, state, {
        selected: action.selected,
      })

    case SELECT_ALL_ROWS:
      return Object.assign({}, state, {
        selected: action.selected,
        selectedAll: !state.selectedAll,
      })

    case CLEAN_SELECTED_ROWS:
      return Object.assign({}, state, {
        selected: [],
      })

    default:
      return state
  }
}
