export const WS_CONNECT = "WS_CONNECT"
export const WS_CONNECT_SUCCESS = "WS_CONNECT_SUCCESS"
export const WS_CONNECT_FAILURE = "WS_CONNECT_FAILURE"
export const WS_MESSAGE = "WS_MESSAGE"

let ws;

export function wsStore(key, query, type) {
	return (dispatch) => {
		dispatch({
			type: `${WS_CONNECT}$`,
			entity: type
		})
		ws = new WebSocket(`${process.env.REACT_APP_STORE_WS}stream/?${query}=${key}`)
		ws.onopen = (e) => {
			dispatch({
				type: WS_CONNECT_SUCCESS,
				entity: type
			})
		}
		ws.onmessage = (e) => {
			dispatch({
				type: WS_MESSAGE,
				entity: type,
				data: JSON.parse(e.data)
			})
		}
	}
}

export function wsClose(type) {
	return (dispatch) => {
		dispatch({
			type: `WS_CLOSE`,
			entity: type
		})
		ws.close()
	}
}
