import {
  GET_PACKAGE_TYPES_REQUEST,
  GET_PACKAGE_TYPES_SUCCESS,
  GET_PACKAGE_TYPES_FAILURE,
} from 'actions/packageTypesActions'
import { PackageTypesActionTypes, PackageTypesState } from './types'

const initialState: PackageTypesState = {
  isFetching: false,
  data: undefined,
  errors: undefined,
}

export default function reducer(
  state = initialState,
  actions: PackageTypesActionTypes,
): PackageTypesState {
  switch (actions.type) {
    case GET_PACKAGE_TYPES_REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case GET_PACKAGE_TYPES_SUCCESS:
      return {
        ...state,
        data: actions.data.results,
        isFetching: false,
      }

    case GET_PACKAGE_TYPES_FAILURE:
      return {
        ...state,
        errors: actions.errors,
        isFetching: false,
      }

    default:
      return state
  }
}
